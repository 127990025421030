import styled from 'styled-components';
export interface ScoringResultBoxInterface {
	padding?: string;
}

const ScoringResultBox = styled.div<ScoringResultBoxInterface>`
	padding: ${({ padding }) => (padding ? `${padding}` : `0`)};
`;

export default ScoringResultBox;
