import React, { useRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Header from '../../atoms/Header/Header';
import Input from '../../atoms/Input/Input';
import { BorderRadiusType, BorderType, ColorType } from '../../../types/types';
import TextArea from '../../atoms/TextArea/TextArea';
import Button from '../../atoms/Button/Button';

const inputProps = {
	border: 'none' as BorderType,
	borderBottom: 'bottom' as BorderType,
	borderRadius: 'none' as BorderRadiusType,
	color: 'landingHeaderBlue' as ColorType,
	padding: '1.6rem 0 0.8rem 0',
	placeholderColor: 'landingHeaderBlue' as ColorType,
};

export interface ModalWrapperInterface {
	isVisible?: boolean;
	handleToggleForm: () => void;
	children: React.ReactNode;
	setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

//TODO: Extract this method to the hooks catalogue if it will be a free time to do so
const useOutsideAlerter = (ref:any) => {
	const [isClickedOutside, setIsClickedOutside] = useState<boolean>(false)
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
				setIsClickedOutside(true)
            } else {
				setIsClickedOutside(false)
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
	}, [ref]);
	return isClickedOutside;
}


const ModalWrapper: React.FC<ModalWrapperInterface> = ({
	isVisible,
	handleToggleForm,
	children,
	setIsVisible
}) => {
	
	const modalRef = useRef<HTMLDivElement | null>(null);
	const outsideClick = useOutsideAlerter(modalRef);

	useEffect(() => {
		setIsVisible(false);
	}, [outsideClick])

	return (
		<>
		{
			isVisible ? (
				<Wrapper
					isVisible={isVisible}
					className={isVisible ? 'show-modal' : undefined}
					ref={modalRef}
				>
					<ContentWrapper>
						<CloseButton onClick={() => handleToggleForm()} />
						<Form>{children}</Form>
					</ContentWrapper>
				</Wrapper>
			) : null
		}
		</>
	);
};

const Wrapper = styled.div<{ isVisible?: boolean }>`
	z-index: 3;
	width: 70rem;
	height: 46rem;
	margin: 0 auto;
	justify-content: center;
	flex-wrap: wrap;
	border-radius: ${({ theme }) => theme.borderRadius.regular};
	background-color: ${({ theme }) => theme.color.white};
	display: none;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	&.show-modal {
		display: block;
	}
	border: 1px solid ${({theme}) => theme.color.landingHeaderBlue}
`;

const ContentWrapper = styled.div`
	width: 62rem;
	margin: 0 auto;
`;

const Form = styled.div`
	width: 100%;
	margin-top: 2.4rem;
`;

const CloseButton = styled.div`
	position: absolute;
	right: 3.5rem;
	top: 3rem;
	transform: translateY(-50%);
	z-index: 5;
	height: 2rem;
	width: 2rem;
	line-height: 4rem;
	text-align: center;
	vertical-align: middle;
	color: ${({ theme }) => theme.color.landingHeaderBlue};
	:after {
		content: '\\e907';
		font-family: ${({ theme }) => theme.font.icon};
		font-size: 1.6rem;
	}
	:hover {
		cursor: pointer;
	}
`;

export default ModalWrapper;
