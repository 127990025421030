import React, { useLayoutEffect, useState } from 'react';
import {  useLocation, Route } from 'react-router-dom';
import { verifyToken } from '../utils/verifyToken';
import { useGetEnv } from '../hooks/useGetEnv';
import { useDispatch } from 'react-redux';
import { isUserAuthenticated } from '../actions/UserActions';
import { API_URL } from '../utils/env';

export type RouteParams = {
	history: any;
	location: any;
	match: any;
	staticContext?: any;
}
interface PublicRouteInterface {
	Component: React.FC<RouteParams>;
	path: string;
	exact?: boolean;
}

const PublicRoute: React.FC<PublicRouteInterface> = ({
	Component: Component,
	path,
	exact,
}) => {
	const [verifiedToken, setVerifiedToken] = useState<boolean>(false);
	const [isLoading, setLoading] = useState<boolean>(true);
	const location = useLocation();
	const dispatch = useDispatch();

	useLayoutEffect(() => {
		setTimeout(() => {
			if (!sessionStorage.getItem('errorMessage')) {
				if (localStorage.getItem('token')) {
					// Comment out verityToken to check token validation
					verifyToken(setVerifiedToken, setLoading, API_URL +'/');
					setVerifiedToken(true);
					dispatch(isUserAuthenticated(true));
				} else {
					// Set setVerifiedToken to false to check token validation
					setVerifiedToken(false);
					dispatch(isUserAuthenticated(false));
				}
			}
		}, 100);
	}, [location.pathname]);

	return (
		<Route
		path={path}
		exact={exact}
		render={
			props =>
		<Component {...props} /> }
			// COMMENT OUT IF CONDITION RENDERING NEEDED
			// verifiedToken ? (
			// 	<>
			// 		{/* Comment out when redirect on login route is needed */}
			// 		<Redirect
			// 			to={{
			// 				pathname: '/',
			// 			}}
			// 		/>
			// 		<Component {...props} />
			// 	</>
			// ) : (
			// 	<>
			// 		<Component {...props} />
			// 	</>
			// )
		// }
		/>
	);
};

export default PublicRoute;
