import React from 'react';
import styled from 'styled-components';
import { WithLoaderInterface } from '../../../types/types';
import Spinner from '../../atoms/Spinner/Spinner';

const WithLoader: React.FC<WithLoaderInterface> = ({
	children,
	isLoading,
	...args
}) => (
	<div>
		{isLoading ? (
			<Wrapper>
				<Spinner {...args} />
			</Wrapper>
		) : (
			children
		)}
	</div>
);

const Wrapper = styled.div`
	position: fixed;
	height: 100vh;
	width: 100%;
	background: ${({ theme }) => theme.color.transparentWhite};
	display: flex;
	align-items: center;
	justify-content: center;
`;

export default WithLoader;
