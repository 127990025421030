import React from 'react';
import styled from 'styled-components';
import { ScoringDetailsInterface } from '../../../types/types';
import ScoringResultBox from '../../atoms/ScoringResultBox/ScoringResultBox';
import Header from '../../atoms/Header/Header';
import P from '../../atoms/Paragraph/Paragraph';
import { printScoringString } from '../../../utils/printScoringString';

const ScoringDetails: React.FC<ScoringDetailsInterface> = ({
	details,
	padding = '1.5rem',
}) => {
	return (
		<ScoringResultBox padding={padding}>
			<Wrapper>
				<InnerWrapper>
					{details.map(item => (
							<React.Fragment key={item.name}>
								<Header
									variant='h4'
									size='h7'
									color='darkGrey'
									weight='regular'
								>
									{item.name}:
								</Header>
								<P color='darkGrey' weight='bold' size='p6'>
									{printScoringString(item.value)}
								</P>
							</React.Fragment>
						))}
				</InnerWrapper>
			</Wrapper>
		</ScoringResultBox>
	);
};

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 100%;
`;

const InnerWrapper = styled.div`
	display: grid;
	grid-column-gap: 0.5rem;
	grid-template-columns: repeat(2, 1fr);
	align-items: center;
	width: 100%;
`;

export default ScoringDetails;
