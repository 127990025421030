import IconsTheme from './partials/IconsTheme';
import ParagraphSizes from './partials/ParagraphSizes';
import HeaderTypes from './partials/HeaderTypes';
import ButtonPartials from './partials/ButtonPartials';
import InputPartials from './partials/InputPartials';
import RatingItemPartials from './partials/RatingItemPartials';
import ShipDetails from './partials/ShipDetails';
import DividerPartials from './partials/DividerPartials';

const Theme = {
	...IconsTheme,
	...ParagraphSizes,
	...HeaderTypes,
	...ButtonPartials,
	...InputPartials,
	...RatingItemPartials,
	...ShipDetails,
	...DividerPartials,
	color: {
		darkBlue: '#00233d',
		lightBlue: '#42b1d5',
		black: '#000000',
		white: '#ffffff',
		transparentWhite: 'rgba(255,255,255,0.5)',
		cardContentBlack: '#212529',
		appBackgroundGrey: '#f7f7f7',
		lightGrey: '#c9c9c9',
		searchGrey: '#C9C9C9',
		searchNavyBlue: '#00233D',
		grey: 'rgba(0,0,0,.5)',
		darkGrey: '#454545',
		transparent: 'transparent',
		separator: 'rgba(0,0,0,0.1)',
		logotypeBlue: '#42B1D5',
		alertColor: 'rgb(13, 60, 97)',
		alertBackground: 'rgb(232, 244, 253)',
		orange: '#F9B202',
		green: '#01852F',
		yellow: '#FFE500',
		landingHeaderBlue: '#235573',
		scrollBackground: '#f5f5f5',
		lime: '#C2CB02',
		red: '#DE0019',
		footerBackground: "#f9f9f9"
	},
	font: {
		weight: {
			light: 300,
			regular: 400,
			medium: 500,
			bold: 700,
			extraBold: 900,
		},
		size: {
			title: {
				h1: 7.2,
				h2: 2.6,
				h3: 2,
				h4: 1.8,
				h5: 1.6,
				h6: 1.4,
				h7: 1.2,
				subcategory: 5.5,
				landingPage: 5.4,
				landingPageSubheader: 3.5,
				contactFormHeader: 3.8,
				heroAlternativeText: 5.6,
				subpageHeader: 6.9,
			},
			button: {
				regular: 1.5,
				small: 1.2,
			},
			paragraph: {
				p1: 2.5,
				p2: 2,
				p3: 1.6,
				p4: 1.4,
				p5: 1.3,
				p6: 1.2,
			},
			ratingItem: {
				regular: 5.5,
				list: 3,
				info: 2,
				detail: 4,
				bottomDescription: 1.4,
				topDescription: 1.8,
			},
			input: 1.6,
			donwnloadLink: 1.5,
			navigation: 1.8,
		},
		lhRatio: {
			title: 1.2,
			paragraph: 1.5,
			input: 1.5,
		},
		icon: 'icomoon',
		text: 'Roboto, sans-serif',
		title: 'Roboto, sans-serif',
	},
	padding: {
		main: '0 1.6rem',
	},
	text: {
		shadow: {
			header: '0 3px 6px rgba(0, 0, 0, 0.16)',
		},
		align: {
			left: 'left',
			center: 'center',
			right: 'right',
			justify: 'justify'
		},
		transform: {
			lowercase: 'lowercase',
			uppercase: 'uppercase',
			capitalize: 'capitalize',
			unset: 'unset',
		},
		letterSpacing: {
			regular: '0.12rem',
			unset: 'unset',
		},
	},
	textStyles: {
		heading: {
			tag: 'h1',
		},
	},
	borderRadius: {
		regular: '4px',
		none: '0px',
		left: '4px 0px 0px 4px',
		onlyBottom: '0 0 4px 4px',
	},
	border: {
		regular: '2px solid #00233d',
		unset: 'unset',
		bottom: '1px solid #235573',
		none: 0,
	},
	boolean: {
		true: true,
		false: false,
	},
};

export default Theme;
