import React from 'react';
import styled, { css } from 'styled-components';
import { CardInterface, CardPhotoInterface } from '../../../types/types';
import EllipsisWrapper from '../../atoms/EllipsisWrapper/EllipsisWrapper';
import Image from '../../atoms/Image/Image';
import Paper from '../../atoms/Paper/Paper';
import Header from '../../atoms/Header/Header';
import P from '../../atoms/Paragraph/Paragraph';
import Button from '../../atoms/Button/Button';
import { Link, useHistory } from 'react-router-dom';
import { routes } from '../../../routes/routes';

const Card: React.FC<CardInterface> = ({
	title,
	subtitle,
	maxLeadLines = 7,
	maxTitleLines = 3,
	lead,
	image,
	imageAlt,
	imageRatio,
	id,
}) => {
	const withImage = !!image;
	const history = useHistory();
	return (
			<OuterWrapepr>
				{image && (
					<div>
						<Image
							src={image}
							alt={imageAlt ? imageAlt : image}
							ratio={imageRatio}
						/>
					</div>
				)}
				<Wrapper withImage={withImage}>
					<ContentWrapper withImage={withImage}>
						<HeaderWrapper withImage={withImage} linesAmount={maxTitleLines}>
							<EllipsisWrapper linesAmount={maxTitleLines}>
								<CenterText>
									<StyledHeader
										variant='h3'
										size={withImage ? 'h3' : 'h4'}
										transform={withImage ? 'unset' : 'uppercase'}
										align='center'
									>
										{title}
									</StyledHeader>
									{subtitle && <P as='span'> {subtitle}</P>}
								</CenterText>
							</EllipsisWrapper>
						</HeaderWrapper>
						<BodyWrapper withImage={withImage}>
							<EllipsisWrapper linesAmount={maxLeadLines}>
								<P align='center' size={withImage ? 'p3' : 'p5' }>{lead}</P>
							</EllipsisWrapper>
						</BodyWrapper>
						{id && (
							<ButtonWrapper>
								<Button
									backgroundColor='white'
									fontColor='lightBlue'
									weight='regular'
									transform='unset'
									onClick={() => history.push(`${routes.news}/${id}`)}
								>
									Read more
								</Button>
							</ButtonWrapper>
						)}
					</ContentWrapper>
				</Wrapper>
			</OuterWrapepr>
	);
};

const ButtonWrapper = styled.div`
	margin-top: 1.5rem;
	display: flex;
	justify-content: flex-end;
`;

const StyledHeader = styled(Header)`
	display: block;
`;

const HeaderWrapper = styled.div<CardPhotoInterface>`
	position: relative;
	padding: 0 4rem;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: -1.9rem;
		height: 1px;
		width: 100%;
		background: ${({ theme }) => theme.color.separator};
	}

	${({ withImage }) =>
		withImage &&
		css`
			padding: 0;
			&:after {
				display: none;
			}
		`}
`;

const CenterText = styled.div`
	margin: auto;
`

const BodyWrapper = styled.div<CardPhotoInterface>`
	padding: 0 4rem;

	${({ withImage }) =>
		withImage &&
		css`
			padding: 0;
		`}
`;

const ContentWrapper = styled.div<CardPhotoInterface>`
	display: grid;
	grid-row-gap: 3.8rem;

	${({ withImage }) =>
		withImage &&
		css`
			height: 100%;
			grid-row-gap: 0.8rem;
			grid-template-rows: max-content 1fr max-content;
		`}
`;

const Wrapper = styled.div<CardPhotoInterface>`
	padding: 2rem 0 3.6rem 0;

	${({ withImage }) =>
		withImage &&
		css`
			padding: 2rem;
		`}
`;

const OuterWrapepr = styled.div`
	display: grid;
	height: 100%;
	grid-template-rows: max-content;
`;

export default Card;
