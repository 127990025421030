import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
	TooltipInterface,
	TooltipIndicatorInterface,
	TooltipContentInterface,
} from '../../../types/types';
import P from '../../atoms/Paragraph/Paragraph';
import Header from '../../atoms/Header/Header';
import UlList from '../../molecules/UnorderedList/UnorderedList';

const Tooltip: React.FC<TooltipInterface> = ({
	title,
	content,
	children,
	position,
	visible = true,
	width,
	listTitle,
	isScoringBarTooltip = false,
	isLeft,
	list,
}) => {
	const [tooltipIndicatorOffsetTop, setTooltipIndicatorOffsetTop] = useState<
		number
	>(0);
	const indicatorRef = useRef<HTMLBaseElement>(null);
	const titleRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (indicatorRef.current && titleRef.current) {
			const tooltipHeight = indicatorRef.current?.offsetParent?.clientHeight;
			const indicatorHeight = indicatorRef.current?.offsetHeight;
			const titleHeight = titleRef.current?.offsetHeight;
			if (
				tooltipHeight &&
				titleHeight > (tooltipHeight - indicatorHeight) / 2
			) {
				setTooltipIndicatorOffsetTop(titleHeight);
			}
		}
	}, [indicatorRef.current, titleRef.current]);

	if (!visible) {
		return <>{children}</>;
	}
	return (
		<Wrapper>
			{children}
			{content && content.length > 0 && (
				<TooltipWrapper
					role='tooltip'
					position={position}
					minOffsetTop={tooltipIndicatorOffsetTop}
					width={width}
					isLeft={isLeft}
				>
					{title && (
						<TooltipTitle ref={titleRef}>
							<Header variant='h3' size='h6' color='white' weight='medium'>
								{title}
							</Header>
						</TooltipTitle>
					)}
					<TooltipContent onlyBottomBorder={title !== undefined}>
						{isScoringBarTooltip ? (
							<>
								<P size='p4'>{listTitle}</P>
								{typeof content === 'object' && (
									<StyledUlList items={content} />
								)}
							</>
						) : (
							<P size='p4' color='darkGrey'>
								{content}
							</P>
						)}
						{list && <StyledUlList color='darkGrey' items={list} />}
					</TooltipContent>
					<TooltipIndicator
						ref={indicatorRef}
						minOffsetTop={tooltipIndicatorOffsetTop}
						position={position}
						isLeft={isLeft}
					/>
				</TooltipWrapper>
			)}
		</Wrapper>
	);
};

const TooltipContent = styled.div<TooltipContentInterface>`
	padding: 1rem 1.2rem;
	border-radius: 0.4rem;
	border: 1px solid ${({ theme }) => theme.color.searchGrey};

	${({ onlyBottomBorder }) =>
		onlyBottomBorder &&
		css`
			border-radius: 0 0 0.4rem 0.4rem;
		`}
`;

const TooltipTitle = styled.div`
	padding: 0.8rem 1.2rem;
	background: ${({ theme }) => theme.color.darkBlue};
	border-radius: 0.4rem 0.4rem 0 0;
	position: relative;
	z-index: 4;
`;

const TooltipWrapper = styled.aside<TooltipIndicatorInterface>`
	background: ${({ theme }) => theme.color.white};
	display: inline-block;
	width: ${({ width }) => (width ? `${width}rem` : 'auto')};
	max-width: 25rem;
	position: absolute;
	top: 50% !important;
	transform: translateY(-50%);
	z-index: -1;
	opacity: 0;
	border-radius: 0.4rem;


	${({ minOffsetTop }) =>
		minOffsetTop > 0 &&
		css`
			top: inherit;
		`}
		

	${({ position }) =>
		position === 'right' &&
		css`
			left: calc(100% + 2rem);
		`}

	${({ isLeft }) =>
		isLeft === true &&
		css`
			left: unset !important;
			right: calc(100% + 2rem) !important;
		`}

	${({ position }) =>
		position === 'left' &&
		css`
			right: calc(100% + 2rem);
		`}
`;

const TooltipIndicator = styled.aside<TooltipIndicatorInterface>`
	display: block;
	clip-path: polygon(100% 10%, 50% 50%, 100% 90%);
	background: ${({ theme }) => theme.color.searchGrey};
	position: absolute;
	left: -2.6rem;
	top: 50%;
	transform: translateY(-50%);
	height: 2.7rem;
	width: 2.7rem;
	z-index: 1;

	${({ minOffsetTop }) =>
		minOffsetTop > 0 &&
		css`
			top: ${() => `${minOffsetTop}px`};
			transform: none;
		`}

	&:before {
		content: '';
		clip-path: polygon(100% 10%, 50% 50%, 100% 90%);
		background: ${({ theme }) => theme.color.white};
		position: absolute;
		left: 0.2rem;
		top: 50%;
		transform: translateY(-50%);
		height: 2.5rem;
		width: 2.5rem;
		z-index: 2;
	}

	${({ position }) =>
		position === 'left' &&
		css`
			left: inherit;
			right: -2.6rem;
			clip-path: polygon(0 10%, 50% 50%, 0 90%);

			&:before {
				left: inherit;
				right: 0.2rem;
				clip-path: polygon(0 10%, 50% 50%, 0 90%);
			}
		`}

	${({ isLeft }) =>
		isLeft === true &&
		css`
			left: inherit;
			right: -2.6rem;
			clip-path: polygon(0 10%, 50% 50%, 0 90%);

			&:before {
				left: inherit;
				right: 0.2rem;
				clip-path: polygon(0 10%, 50% 50%, 0 90%);
			}
		`}
`;

const Wrapper = styled.div`
	position: relative;
	&:hover > ${TooltipWrapper} {
		opacity: 1;
		z-index: 10;
	}
`;

const StyledUlList = styled(UlList)`
	padding-left: 3rem;
`;

export default Tooltip;
