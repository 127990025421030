export const SHIP_LOADING = 'SHIP_LOADING';
export const SHIP_FAIL = 'SHIP_FAIL';
export const SHIP_SUCCESS = 'SHIP_SUCCESS';
export const SHIP_SAVING_ID = 'SHIP_SAVING_ID';
export const SHIP_SAVING_ID_FAIL = 'SHIP_SAVING_ID_FAIL';
export const FLEET = 'FLEET';
export const ADD_TO_FLEET = 'ADD_TO_FLEET';
export const SUCCESS = 'SUCCESS';
export const ADD_TO_MANAGER_SHIPS = 'ADD_TO_MANAGER_SHIPS';
export const SET_MANAGER_SHIPS_URL = 'SET_MANAGER_SHIPS_URL';
export const UPDATE_SHIP = 'UPDATE_SHIP'
export const FAIL = 'FAIL'
export const PAGINATION_LOADING_ON = 'PAGINATION_LOADING_ON'
export const PAGINATION_LOADING_OFF = 'PAGINATION_LOADING_OFF'
export const BLOCK_BUTTON = 'BLOCK_BUTTON'
export const UNBLOCK_BUTTON = 'UNBLOCK_BUTTON'

export type ShipDispatchTypes =
	| ShipLoading
	| ShipFail
	| ShipSuccess
	| ShipSavingId
	| ShipSavingIdFail
	| Fleet
	| AddToFleet
	| Success
	| AddToManagerShips
	| SetManagerShipsUrl
	| UpdateShip
	| PaginationLoadingOn
	| PaginationLoadingOff
	| BlockButton
	| UnblockButton
	| Fail;

export interface ShipLoading {
	type: typeof SHIP_LOADING;
}

export interface ShipFail {
	type: typeof SHIP_FAIL;
}

export interface ShipSuccess {
	type: typeof SHIP_SUCCESS;
	payload: any;
}

export interface Success {
	type: typeof SUCCESS;
}

export interface PaginationLoadingOn {
	type: typeof PAGINATION_LOADING_ON;
}

export interface PaginationLoadingOff {
	type: typeof PAGINATION_LOADING_OFF;
}

export interface Fail {
	type: typeof FAIL;
}

export interface ShipSavingId {
	type: typeof SHIP_SAVING_ID;
	payload: number;
}

export interface ShipSavingIdFail {
	type: typeof SHIP_SAVING_ID_FAIL;
}

export interface Fleet {
	type: typeof FLEET;
	nextUrl: string;
	payload: any;
}

export interface AddToFleet {
	type: typeof ADD_TO_FLEET;
	payload: [];
	nextUrl: string;
}

export interface AddToManagerShips {
	type: typeof ADD_TO_MANAGER_SHIPS
	payload: [];
	nextUrl: string;
}

export interface SetManagerShipsUrl {
	type: typeof SET_MANAGER_SHIPS_URL
	payload: string;
}

export interface UpdateShip {
	type: typeof UPDATE_SHIP;
	payload: any

}

export interface BlockButton {
	type: typeof BLOCK_BUTTON;
	payload: number
}

export interface UnblockButton {
	type: typeof UNBLOCK_BUTTON;
	payload: number
}
