import React from 'react';
import styled from 'styled-components';
import { ContentHandlerInterface } from '../../../types/types';
import { useLocation } from 'react-router-dom';
import { routes } from '../../../routes/routes';

const whiteBackgroundPages = [routes.homepage, routes.news];

const ContentHandler: React.FC<ContentHandlerInterface> = ({ children }) => {
	const { pathname } = useLocation();
	return <Wrapper pathname={pathname}>{children}</Wrapper>;
};

const Wrapper = styled.main<ContentHandlerInterface>`
	height: ${({ isLandingPage, pathname }) =>
		pathname && pathname.includes(routes.landingPage) ? '100%' : 'unset'};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: ${({ theme, pathname }) =>
		pathname && whiteBackgroundPages.includes(`/${pathname.split('/')[1]}`)
			? theme.color.white
			: theme.color.appBackgroundGrey};
`;

export default ContentHandler;
