import axios, { Method, AxiosPromise } from 'axios';
import { ContactFormData, UnknownObjectType } from '../types/types';
import { API_URL } from './env';
import { keysToSnake } from './stringUtils';
axios.defaults.headers.common['Content-Type'] = 'application/json';

type refreshTokenPromiseType = Promise<void> | null;

export type PAGINATION_RESULT = {
	results?: any;
	next?: string;
	error?: {
		code?: string;
		description?: string;
	}
}

export class FetcherService {
	static maxRefreshTokenRetries = 3;
	static refreshTokenPromise: refreshTokenPromiseType = null;
	
	static getConfigForProtectedAPI = (method: Method, endpoint: string, data?: any,) => {
		const token = localStorage.getItem('token');
		return {
			method: method,
			headers: { Authorization: 'Bearer ' + token },
			url: endpoint,
			data: data,
		}
	}

	static logout() {
		console.log('SERVICE_LOGOUT_USER');
	}

	static async refreshToken() {
		const data = { refresh: localStorage.getItem('refresh') };
		await axios
			.post(`${API_URL}/api/token/refresh/`, data)
			.then(({ data }) => {
				localStorage.setItem('token', data.access);
			})
			.catch(() => {
				this.logout();
			});
	}

	static async get<APIResultType>(endpoint: string) {
		try {
			const response = await axios.get<APIResultType>(endpoint);
			const { data } = response;
			return JSON.stringify(data);
		} catch (error) {
			throw error;
		}
	}

	static async post<APIResultType>(
		endpoint: string,
		postData: UnknownObjectType
	) {
		try {
			const response = await axios.post<APIResultType>(endpoint, postData);
			const { data } = response;
			return JSON.stringify(data);
		} catch (error) {
			throw error;
		}
	}

	static async protectedRequest<APIResultType>(
		endpoint: string,
		postData?: UnknownObjectType,
		method = 'GET' as Method,
		retry = 1,
	): Promise<APIResultType> {
		if (this.refreshTokenPromise !== null) {
			await this.refreshTokenPromise;
		}
		const config = this.getConfigForProtectedAPI(method, endpoint, postData);
		return axios(config).then(
				(response) => {
					const { data } = response
					return data
				},
				async (error) => {
					if (error.response.status === 401) {
							if (retry <= this.maxRefreshTokenRetries) {
								try {
									if (this.refreshTokenPromise !== null) {
										await this.refreshTokenPromise;
									} else {
										this.refreshTokenPromise = this.refreshToken();
										await this.refreshTokenPromise;
										this.refreshTokenPromise = null;
									}
									const nextRetry = retry + 1;
									return await this.protectedRequest<APIResultType>(
										endpoint,
										postData,
										method,
										nextRetry
									);
								} catch (afterRefreshError) {
									throw afterRefreshError;
								}
							} else {
								this.logout();
								throw error;
							}
						}
					else if (error.response.status === 500) {
						throw error;
					}
					else {
						return Promise.reject(error.response);
					}
				});
	}

	static async login<APIResultType>(email: string, password: string) {
		const endpoint = `${API_URL}/api/token/`;
		return await this.post(endpoint, { email, password });
	}
	
	static async contact<APIResultType>(contactData: ContactFormData) {
		const endpoint = `${API_URL}/api/contacts/`;
		const payload = keysToSnake(contactData)
		return await this.post(endpoint, payload)
	}

	static async getListOfShipsAndFilterByImo<ShipResponseInterface>(
		imo: string | null
	) {
		const endpoint = `${API_URL}/api/ships/?search=${imo}`;
		return await this.protectedRequest<any>(endpoint, {}, 'GET');
	}

	static async getShipById<ShipResponseInterface>(id: number) {
		const endpoint = `${API_URL}/api/ships/${id}/`;
		return await this.protectedRequest<any>(endpoint, {}, 'GET').then(async response => {
			const data = response;
			id = data.id;
			return await this.checkIfInMyFleet([id]).then(response => ({
				...data,
				is_in_my_fleet: response[id].is_in_my_fleet,
				fleet_ship_id: response[id].fleet_id
			}))
		});
	}

	static async getAutocompleteSuggestions(query: string) {
		const endpoint = `${API_URL}/api/ships/autocomplete?q=${query}`;
		return [query, await this.get(endpoint)];
	}
	
	static async addToMyFleet(id: number) {
		const endpoint = `${API_URL}/api/fleetship/`
		try {
			return await this.protectedRequest<any>(endpoint, {"ship": id}, 'POST')
		}
		catch (err) {
			if (err.status === 400) {
				return Promise.resolve(err);
			}
			else {
				throw err;
			}
		}
		
	}
	static async checkIfInMyFleet(ids: number[]) {
		const endpoint = `${API_URL}/api/fleets/is-in-my-fleet/`;
		const result = await this.protectedRequest<any>(endpoint, {"ships": ids}, 'POST');
		return Promise.resolve(result)
	}
	
	static async removeFromMyFleet(id: number) {
		const endpoint = `${API_URL}/api/fleetship/${id}/`;
		return await this.protectedRequest(endpoint,{"ship": id}, 'DELETE')
	}
	
	
}
