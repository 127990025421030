export const printScoringString = (
	data: string | null | undefined | number
) => {
	if (typeof data === 'string') {
		return data;
	} else if (typeof data === 'number') {
		return String(data);
	} else {
		return 'n/v';
	}
};
