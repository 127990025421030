import Spinner from '../Spinner/Spinner';
import React from 'react';
import styled from 'styled-components';

type CenterSpinnerProps = {
    loading: boolean;
}

const CenterSpinner: React.FC<CenterSpinnerProps> = ({loading}) => {
    return 	<SpinnerWrapper>
					<Spinner isLoading={loading}/>
				</SpinnerWrapper>
}

const SpinnerWrapper = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
`

export default CenterSpinner;
