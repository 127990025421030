import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { routes } from '../../../routes/routes';
import Image from '../Image/Image';

export interface LogotypeInterface {
	isLandingPage?: boolean;
	isLogo?: boolean;
	isMaterial?: boolean;
}

const Logotype: React.FC<LogotypeInterface> = ({
	isLandingPage = false,
	isLogo,
	isMaterial,
}) => (
		<NavLink to={routes.homepage}>
			{isLandingPage ? (
				<Image isLogo={isLogo} src='/images/logotype.svg' alt='logotype' ratio={0} />
			) : (
					<Image
						isLogo={isLogo}
						src='/images/logotype-color.svg'
						alt='logotype'
						ratio={0}
					/>
				)
			}
		</NavLink>
	);

const StyledLogotype = styled.h1`
	font-size: 3.4rem;
`;

const ColoredLogotype = styled.span`
	color: ${({ theme }) => theme.color.logotypeBlue};
`;

export default Logotype;
