import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink, Link } from 'react-router-dom';
import {
    NavigationLinkInterface,
    NavigationLinkComponentInterface,
} from '../../../types/types';
import Icon from '../Icon/Icon';
import ContactModal from '../../organisms/ContactModal/ContactModal';

const NavigationLink: React.FC<NavigationLinkInterface> = ({
   icon,
   to,
   children,
   destination,
   isLastLink,
   type,
   target,
   isLandingPage,
   isMaterial,
}) => (
    <StyledLi>
        {type ? (
            <StyledNavLink
                target={target}
                to={to}
                isHeaderVersion={destination === 'header'}
                isLandingPage={isLandingPage}
                isMaterial={isMaterial}
            >
                {children} {icon && <Icon iconType={icon} size={1.4}/>}
            </StyledNavLink>
        ) : (
            <StyledNavLink
                target={target}
                to={to}
                isHeaderVersion={destination === 'header'}
                isLandingPage={isLandingPage}
                isMaterial={isMaterial}
            >
                {children} {icon && <Icon iconType={icon} size={1.4}/>}
            </StyledNavLink>
        )}
        {destination === 'footer' && (
            <>
                <VerticalSeparator>|</VerticalSeparator>
                <ContactModal isFooter={true}/>
            </>
        )}
        {destination === 'footer' && !isLastLink && (
            <VerticalSeparator>|</VerticalSeparator>
        )}
    </StyledLi>
);

const StyledLi = styled.li`
	display: flex;
	align-items: center;
`;

const VerticalSeparator = styled.div`
	height: ${({theme}) =>
    `${theme.font.lhRatio.paragraph * theme.font.size.paragraph.p3}rem`};
	color: ${({theme}) => theme.color.black};
	display: flex;
	align-items: center;
`;

const StyledNavLink = styled(NavLink) <NavigationLinkComponentInterface>`
	${({isHeaderVersion, isLandingPage}) =>
    isHeaderVersion
        ? isLandingPage
        ? css`
						padding: 0.8rem;
						color: ${({theme}) => theme.color.white};
						margin: 0 2.2rem;
						line-height: ${({theme}) =>
            `${theme.font.lhRatio.paragraph * theme.font.size.paragraph.p2
            }rem`};
						font-size: ${({theme}) => `${theme.font.size.navigation}rem`};
						
						&:hover {
							color: ${({theme}) => theme.color.black};
						}
						
						&.active {
							font-weight: ${({theme}) => theme.font.weight.extraBold};
						}
					  `
        : css`
						padding: 0.8rem;
						color: ${({theme}) => theme.color.landingHeaderBlue};
						margin: 0 2.2rem;
						line-height: ${({theme}) =>
            `${theme.font.lhRatio.paragraph * theme.font.size.paragraph.p2
            }rem`};
						font-size: ${({theme}) => `${theme.font.size.navigation}rem`};
						
						&:hover {
							color: ${({theme}) => theme.color.black};
						}
						
						&.active {
							font-weight: ${({theme}) => theme.font.weight.extraBold};
						}
					  `
        : css`
					//FOOTER
					color: ${({theme}) => theme.color.black};
					margin: 0 1.5rem;
					font-size: ${({theme}) => `${theme.font.size.paragraph.p3}rem`};
					line-height: ${({theme}) =>
            `${theme.font.lhRatio.paragraph * theme.font.size.paragraph.p3
            }rem`};

					&:hover {
						color: ${({theme}) => theme.color.darkBlue}
					}
			  `}
`;

export default NavigationLink;
