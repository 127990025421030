import React from 'react';
import styled from 'styled-components';
import { ScoringNavigationInterface } from '../../../types/types';
import ScoringResultBox from '../../atoms/ScoringResultBox/ScoringResultBox';
import Header from '../../atoms/Header/Header';
import P from '../../atoms/Paragraph/Paragraph';

const ScoringHighlitedInformation: React.FC<ScoringNavigationInterface> = ({
	environmentalScore,
	reliabilityScore,
	sustainabilityScore,
}) => {
	const informationArray = [
		{ name: 'Environmental', value: environmentalScore },
		{ name: 'Reliability', value: reliabilityScore },
		{ name: 'Sustainability', value: sustainabilityScore },
	];
	return (
		<Wrapper>
			<StyledScoringResultBox>
				<InnerWrapper>
					{informationArray.map(({ name }) => (
						// <ItemWrapper key={name}>
						<Header
							variant='h3'
							size='h6'
							color='white'
							align='center'
							weight='medium'
							key={name}
						>
							{name}
						</Header>
						// </ItemWrapper>
					))}
					{informationArray.map(({ name, value }) => (
						// <ItemWrapper key={name}>
						<React.Fragment key={name}>
								<P size='p1' color='white' align='center' weight='extraBold'>
									{value}
								</P>
						</React.Fragment>
						// </ItemWrapper>
					))}
				</InnerWrapper>
			</StyledScoringResultBox>
		</Wrapper>
	);
};

const StyledP = styled(P)`
	line-height: 3.68rem;
`;

const ItemWrapper = styled.div`
	/* display: flex;
	flex-direction: column;
	justify-content: space-between; */
	display: grid;
	grid-template-rows: repeat(2, max-content);
`;

const InnerWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(3, calc(100% / 3));
	grid-template-rows: repeat(2, max-content);
	grid-row-gap: 2rem;
	width: 100%;
	align-items: center;
`;

const StyledScoringResultBox = styled(ScoringResultBox)`
	/* padding: 1.5rem 0.5rem; */
	${P} {
		line-height: 1.2;
	}
	height: 100%;
	display: flex;
	align-items: center;
`;

const Wrapper = styled.div`
	background: ${({ theme }) => theme.color.darkBlue};
`;

export default ScoringHighlitedInformation;
