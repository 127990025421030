import React, { useRef, useState, useMemo, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { ImageInterface } from '../../../types/types';

const Image: React.FC<ImageInterface> = ({
	alt,
	ratio = 1.54,
	className,
	isLogo,
	src,
	objectFit
}) => {
	const wrapperRef = useRef<HTMLDivElement>(null);
	const [imageHeight, setImageHeight] = useState<number>(0);

	const updateSize = () => {
		if (wrapperRef.current) {
			if (ratio > 0) {
				setImageHeight(wrapperRef.current.offsetWidth / ratio);
			}
		}
	};

	useLayoutEffect(() => {
		updateSize();
	}, []);

	useMemo(() => {
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);

	return (
		<Wrapper
			ref={wrapperRef}
			style={{ height: `${imageHeight > 0 ? `${imageHeight}px` : `100%`}` }}
			className={className}
		>
			<StyledImage isLogo={isLogo} src={src} alt={alt} objectFit={objectFit} />
		</Wrapper>
	);
};

const StyledImage = styled.img<{ isLogo?: boolean, objectFit?: string }>`
	position: ${({ theme, isLogo }) => (isLogo ? 'unset' : 'absolute')};
	width: 100%;
	height: 100%;
	top: ${({ theme, isLogo }) => (isLogo ? 'unset' : '50%')};
	transform: ${({ theme, isLogo }) => (isLogo ? 'unset' : 'translatey(-50%)')};
	object-fit: ${({theme, objectFit}) => (objectFit ? objectFit : 'unset')};
`;

const Wrapper = styled.div`
	width: 100%;
	overflow: hidden;
	position: relative;
`;

export default Image;
