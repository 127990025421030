export const routes = {
	homepage: '/',
	landingPage: '/welcome',
	fleet: '/my-fleet',
	scoring: '/check-scorings',
	methodology: '/methodology',
	logout: '/logout',
	impressum: '/impressum',
	subscriptionModels: '/subscription-models',
	contact: '/contact',
	news: '/news',
	environmentalDetails: '/environmental-details',
	reliabilityDetails: '/reliability-details',
	sustainabilityDetails: '/sustainability-details',
	login: '/login',
	about: '/about',
	managerShips: '/manager-ships',
};
