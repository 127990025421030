import React from 'react';
import styled, { css } from 'styled-components';
import { TextAreaInterface } from '../../../types/types';

const TextArea: React.FC<TextAreaInterface> = ({
	children,
	borderBottom = 'bottom',
	border = 'none',
	placeholder,
	padding,
	placeholderColor,
	color,
	name
}) => (
	<Wrapper>
		<TextAreaComponent
			placeholder={placeholder}
			border={border}
			borderBottom={borderBottom}
			padding={padding}
			placeholderColor={placeholderColor}
			color={color}
			name={name}
		>
			{children}
		</TextAreaComponent>
	</Wrapper>
);

const Wrapper = styled.div``;

const TextAreaComponent = styled.textarea<TextAreaInterface>`
	width: 100%;
	border: ${({ theme, border }) => border && theme.border[border]};
	border-bottom: ${({ theme, borderBottom }) =>
		borderBottom && theme.border.bottom};
	font-size: ${({ theme }) => `${theme.font.size.input}rem`};
	line-height: ${({ theme }) =>
		`${theme.font.size.input * theme.font.lhRatio.input}rem`};
	padding: ${({ theme, padding }) => (padding ? padding : '1.6rem 1.2rem')};
	resize: vertical;
	height: 5.1rem;
	max-height: 9.6rem;
	min-height: 5.1rem;
	font-family: Roboto;
	position: relative;

	&::-webkit-resizer {
		z-index: 1;
		background-image: url('/images/icons/resizer.svg');
		background-position: center;
		background-repeat: no-repeat;
		opacity: 1;
	}

	color: ${({ theme, color }) => (color ? theme.color[color] : 'inherit')};
	${({ placeholderColor }) =>
		placeholderColor &&
		css`
			&::placeholder {
				color: ${({ theme }) =>
					placeholderColor && theme.color[placeholderColor]};
			}
		`};
`;

export default TextArea;
