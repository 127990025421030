import React from 'react';
import styled, { css } from 'styled-components';
import { InputInterface, InputComponentInterface } from '../../../types/types';
import Icon from '../Icon/Icon';

const Input: React.FC<InputInterface> = args => (
	<Wrapper>
		{args.icon && (
			<IconWrapper>
				<Icon
					iconType={args.icon}
					size={2.3}
					color='searchGrey'
					weight={args.iconWeight}
				/>
			</IconWrapper>
		)}
		<StyledInput {...args} />
	</Wrapper>
);

const StyledInput = styled.input<InputComponentInterface>`
	background: ${({ theme }) => theme.color.white};
	width: 100%;
	color: ${({ theme, color }) => (color ? theme.color[color] : 'inherit')};
	padding: ${({ theme, padding }) =>
		padding ? `${padding}` : `1.6rem 1.2rem`};
	font-size: ${({ theme }) => `${theme.font.size.input}rem`};
	line-height: ${({ theme }) =>
		`${theme.font.size.input * theme.font.lhRatio.input}rem`};

	border-radius: ${({ theme, borderRadius }) =>
		borderRadius
			? theme.borderRadius[borderRadius]
			: theme.borderRadius.regular};

	border: ${({ theme, border }) =>
		border ? theme.border[border] : theme.border.regular};
	border-bottom: ${({ theme, borderBottom }) =>
		borderBottom && theme.border[borderBottom]};
	${({ icon }) =>
		icon &&
		css`
			padding-left: 5.7rem;
		`};

	${({ placeholderColor }) =>
		placeholderColor &&
		css`
			&::placeholder {
				color: ${({ theme }) =>
					placeholderColor && theme.color[placeholderColor]};
			}
		`};
`;

const IconWrapper = styled.div`
	position: absolute;
	top: 50%;
	left: 1.7rem;
	transform: translateY(-50%);
	pointer-events: none;
`;

const Wrapper = styled.div`
	position: relative;
`;

export default Input;
