import React from 'react';
import styled from 'styled-components';
import MainWrapper from '../../atoms/MainWrapper/MainWrapper';
import ScoringResultItemNew from '../../molecules/ScoringResultItem/ScoringResultItemNew';
import EmptyContent from '../../molecules/EmptyContent/EmptyContent';
import { keysToCamel } from '../../../utils/stringUtils';
import { ScoreResultsInterface } from '../../../types/types';

const ScoringResultsNew: React.FC<ScoreResultsInterface> = ({data}) => {
	return (
		<ContentWrapper>
			{/*{!isLoading ? (*/}
				<MainWrapper>
					<Wrapper>
						{data ? (
							data.map((args, index) => (
								<ScoringResultItemNew
									key={index}
									{...keysToCamel(args)}
								/>
							))
						) : (
							<EmptyContent />
						)}
					</Wrapper>
				</MainWrapper>
			{/*) : (*/}
			{/*	/!*<WithLoader isLoading={isLoading} />*!/*/}
			{/*)}*/}
		</ContentWrapper>
	);
};

const Wrapper = styled.div`
	display: grid;
	grid-row-gap: 3.7rem;
`;

const ContentWrapper = styled.section`
	min-height: 31.4rem;
`;

export default ScoringResultsNew;
