import { Dispatch } from 'redux';
import {
	ShipDispatchTypes,
	SHIP_FAIL,
	SHIP_SUCCESS,
	SHIP_LOADING,
	SHIP_SAVING_ID,
	SHIP_SAVING_ID_FAIL,
	ADD_TO_FLEET,
	SUCCESS,
	ADD_TO_MANAGER_SHIPS,
	UPDATE_SHIP,
	FAIL,
	PAGINATION_LOADING_ON,
	PAGINATION_LOADING_OFF,
	BLOCK_BUTTON,
	UNBLOCK_BUTTON, FLEET,
} from './ShipActionTypes';
import { FetcherService } from '../utils/FetcherService';
import Store from '../Store';
import { MyFleetShipResponse } from '../types/types';
export const GetShipById = (id: number) => (
	dispatch: Dispatch<ShipDispatchTypes>
) => {
	try {
		dispatch({
			type: SHIP_LOADING,
		});
		let data: any;
		FetcherService.getShipById(id).then(response => {
			data = response;
			dispatch({
				type: SHIP_SUCCESS,
				payload: data,
			});
		});
	} catch (e) {
		dispatch({
			type: SHIP_FAIL,
		});
	}
};

export const SaveShipId = (id: number) => (
	dispatch: Dispatch<ShipDispatchTypes>
) => {
	try {
		dispatch({
			type: SHIP_SAVING_ID,
			payload: id,
		});
	} catch (e) {
		dispatch({
			type: SHIP_SAVING_ID_FAIL,
		});
	}
};
export const FetchNextFleetPage = () => (
	dispatch: Dispatch
) => {
	const store = Store.getState()
	const url = store.ship.nextFleetUrl
	try {
	FetcherService.protectedRequest<any>(url).then(async data => {
			let ships = data.fleetship_set.map((obj:any) => obj['ship']);
			if (ships) {
				if (store.ship.fleet) {
					dispatch({ type: SUCCESS })
				}
				const ids = ships.map((ship: any) => ship.id)
				const inMyFleetResults = await FetcherService.checkIfInMyFleet(ids)
				const shipsWithInMyFleetCheck = ships.map((ship: any) => (
					{
					...ship,
					is_in_my_fleet: inMyFleetResults[ship.id].is_in_my_fleet,
					fleet_id: inMyFleetResults[ship.id].fleet_id
					})
				)
				dispatch({
					type: ADD_TO_FLEET,
					payload: shipsWithInMyFleetCheck,
				});
			}
		})
		.catch(err => {
			if (err.status === 404) {
					dispatch({
						type: FLEET,
						payload: [],
						// nextUrl: '',
					})
			}
			else {
				throw err
			}
		})
	} catch (e) {
		dispatch({
			type: FAIL
		})
	}
}


export const FetchNextManagerShipsPage = () => (
	dispatch: Dispatch
) => {
	const store = Store.getState()
	const url = store.ship.nextManagerShipsUrl
	if (store.ship.loading || !url) return;
	try {
		dispatch({
			type: SHIP_LOADING,
		});
		FetcherService.get(url).then(async response => {
			let data = JSON.parse(response);
			let results = data.results;
			if (results && results.length > 0) {
				const ids = results.map((ship: any) => ship.id);
				const inMyFleetResults = await FetcherService.checkIfInMyFleet(ids);
				const shipsWithInMyFleetCheck = results.map((ship_2: any) => (
					{
						...ship_2,
						is_in_my_fleet: inMyFleetResults[ship_2.id].is_in_my_fleet,
						fleet_id: inMyFleetResults[ship_2.id].fleet_id
					})
				);
				dispatch({
					type: ADD_TO_MANAGER_SHIPS,
					payload: shipsWithInMyFleetCheck,
					nextUrl: data.next ?? ''
				});
				dispatch({
					type: SUCCESS,
				});
			}
		})
	} catch (e) {
		console.log(e)
		dispatch({
			type: SHIP_FAIL
		})
	}
}
export const FetchNextVesselsPage = () => (
	dispatch: Dispatch
) => {
	const store = Store.getState()
	const url = store.ship.ship.nextSisterUrl
	if (store.ship.paginationLoading || store.ship.loading || !url) return;
	try {
		dispatch({
			type: PAGINATION_LOADING_ON,
		});
		FetcherService.get(url).then(async r => {
			const response = await JSON.parse(r)
			const ids = response.results.map((ship: any) => ship.id);
			const inMyFleetResults = await FetcherService.checkIfInMyFleet(ids);
			const shipsWithInMyFleetCheck = response.results.map((ship_2: any) => (
				{
					...ship_2,
					is_in_my_fleet: inMyFleetResults[ship_2.id].is_in_my_fleet,
					fleet_id: inMyFleetResults[ship_2.id].fleet_id
				})
			);
			dispatch({
				type: UPDATE_SHIP,
				payload: {
					sisterVessels: store.ship.ship.sisterVessels.concat(shipsWithInMyFleetCheck) ?? [],
					nextSisterUrl: response.next
				}
			})
			dispatch({
				type: PAGINATION_LOADING_OFF,
			});
		})
	} catch (e) {
		console.log('Vessels error', e)
		dispatch({
			type: SHIP_FAIL
		})
		dispatch({
			type: PAGINATION_LOADING_OFF,
		});
	}
}

export const globalLoadingOn = () => (
	dispatch: Dispatch
) => {
	dispatch({
		type: SHIP_LOADING
	})
}

export const globalLoadingOff = () => (
	dispatch: Dispatch
) => {
	dispatch({
		type: SUCCESS
	})
}
type ToggleFleetResponse = {
	state: 'added' | 'removed'
	fleet_id?: number | null
	msg?: string;
}



export const toggleShipMyFleet = async (
	dispatch: Dispatch,
	id: number,
	isInMyFleet: boolean,
	fleetShipId: number | null,
	shipData: MyFleetShipResponse
): Promise<ToggleFleetResponse> => {
	const store = Store.getState();
	const blocked = store.ship.buttonsBlocked.find(shipId => shipId === id);
	if (blocked) return Promise.reject('Blocked');
	if (!isInMyFleet) {
		dispatch({
			type: BLOCK_BUTTON,
			payload: id
		});
		const response = await FetcherService.addToMyFleet(id);
		const ships = store.ship.fleet;
		if (ships && !ships.find((ship: any) => ship.id === id)) {
			ships.push(shipData);
			const ids = ships.map((ship_1: any) => ship_1.id);
			const inMyFleetResults = await FetcherService.checkIfInMyFleet(ids);
			const shipsWithInMyFleetCheck = ships.map((ship_2: any) => (
				{
					...ship_2,
					is_in_my_fleet: inMyFleetResults[ship_2.id].is_in_my_fleet,
					fleet_id: inMyFleetResults[ship_2.id].fleet_id
				})
			);
			dispatch({
				type: FLEET,
				payload: shipsWithInMyFleetCheck,
			});
			dispatch({type: UNBLOCK_BUTTON, payload: id});
			return Promise.resolve({state: 'added', ...response});
		}
		dispatch({type: UNBLOCK_BUTTON, payload: id});
		return Promise.resolve({state: 'added'});
	} else if (fleetShipId) {
		dispatch({type: BLOCK_BUTTON, payload: id});
		await FetcherService.removeFromMyFleet(fleetShipId);
		// Only filter if already fleet was fetched
		if (store.ship.fleet) {
			const newFleet = store.ship.fleet.filter((ship_3: any) => ship_3.fleet_id !== fleetShipId);
			dispatch({
				type: FLEET,
				payload: newFleet
			});
			dispatch({
				type: SUCCESS
			});
		}
		dispatch({type: UNBLOCK_BUTTON, payload: id});
		return Promise.resolve({state: 'removed'});
	} else {
		return Promise.reject('Already added')
	}
}
