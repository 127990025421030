import React from 'react';
import styled from 'styled-components';
import {
	ButtonComponentInterface,
	ButtonInterface,
} from '../../../types/types';

const Button: React.FC<ButtonInterface> = ({
	children,
	type = 'button',
	...args
}) => <StyledButton {...args}>{children}</StyledButton>;

const StyledButton = styled.button<ButtonComponentInterface>`
	padding: ${({ theme, padding }) => (padding ? padding : '0')};
	width: ${({ theme, width }) =>
		width ? `${theme.ButtonVariantTypes[width]}rem` : 'inherit'};
	height: ${({ theme, height }) =>
		height ? `${theme.ButtonHeightTypes[height]}rem` : 'inherit'};
	color: ${({ theme, fontColor }) =>
		fontColor ? theme.color[fontColor] : 'inherit'};
	font-size: ${({ theme, fontSize }) =>
		fontSize
			? `${theme.font.size.button[fontSize]}rem`
			: `${theme.font.size.button.regular}rem`};
	background-color: ${({ theme, backgroundColor }) =>
		backgroundColor ? theme.color[backgroundColor] : 'inherit'};
	text-transform: ${({ theme, transform }) =>
		transform
			? `${theme.text.transform[transform]}`
			: theme.text.transform.uppercase};
	font-weight: ${({ theme, fontWeight }) =>
		fontWeight ? theme.font.weight[fontWeight] : theme.font.weight.bold};
	letter-spacing: ${({ theme, letterSpacing }) =>
		letterSpacing
			? theme.text.letterSpacing[letterSpacing]
			: theme.text.letterSpacing.regular};
	border-radius: ${({ theme, borderRadius }) =>
		borderRadius
			? theme.borderRadius[borderRadius]
			: theme.borderRadius.regular};
	border: ${({ theme, border }) =>
		border ? theme.border[border] : theme.border.unset};
	border-color: ${({ theme, borderColor }) =>
		borderColor ? theme.color[borderColor] : theme.color.orange};
`;

export default React.memo(Button);
