import React from 'react';
import styled from 'styled-components';
import MainWrapper from '../../atoms/MainWrapper/MainWrapper';
import AdvantageItem from '../../molecules/AdvantageItem/AdvantageItem';
import { ColorType, IconType } from '../../../types/types';

const advantageList = [
	{ icon: 'podium' as IconType, title: 'Largest European Rating Agency' },
	{ icon: 'ship' as IconType, title: 'More than 70.000 ships' },
	{ icon: 'goal' as IconType, title: 'More than 20 years of experience' },
];

export type AdvantagesBarProps =  {
	backgroundColor?: string;
	padding?: string;
	backgroundColorSingleBar?: string;
	color?: ColorType;
	iconColor?: string;
}

const AdvantagesBar: React.FC<AdvantagesBarProps> = ({
   	backgroundColor,
	padding,
	backgroundColorSingleBar,
	iconColor,
	color
}) => (
	<Wrapper backgroundColor={backgroundColor}>
		<MainWrapper>
			<InnerWrapper>
				{advantageList.map((args, index) => (
					<AdvantageItem
						key={index}
						icon={args.icon}
						title={args.title}
						backgroundColor={backgroundColorSingleBar}
						padding={padding}
						iconColor={iconColor}
						color={color}
					/>
				))}
			</InnerWrapper>
		</MainWrapper>
	</Wrapper>
);

const InnerWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

type WrapperInterface = {
	backgroundColor?: string;
}

const Wrapper = styled.section<WrapperInterface>`
	padding: 2rem 0;
	margin-top: -1px;
	background: ${({ theme, backgroundColor }) => backgroundColor ? backgroundColor : theme.color.darkBlue};
`;

export default AdvantagesBar;
