import React, { useRef, useState } from 'react';
import styled  from 'styled-components';
import Header from '../../atoms/Header/Header';
import Input from '../../atoms/Input/Input';
import { BorderRadiusType, BorderType, ColorType, ContactFormData } from '../../../types/types';
import TextArea from '../../atoms/TextArea/TextArea';
import Button from '../../atoms/Button/Button';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import { FetcherService } from '../../../utils/FetcherService';

const inputProps = {
	border: 'none' as BorderType,
	borderBottom: 'bottom' as BorderType,
	borderRadius: 'none' as BorderRadiusType,
	color: 'landingHeaderBlue' as ColorType,
	padding: '1.6rem 0 0.8rem 0',
	placeholderColor: 'landingHeaderBlue' as ColorType,
	required: true
};

export interface ContactFormInterface {
	isVisible?: boolean;
	handleToggleForm: () => void;
	setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContactForm: React.FC<ContactFormInterface> = ({
	handleToggleForm,
	isVisible,
	setIsVisible
}) => {
	
	const form = useRef<any>();
	
	const [contactData, setContactData] = useState<ContactFormData>({
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		companyName: '',
		message: ''
	});
	
	const handleSend = async () => {
		if (form.current && form.current.checkValidity()) {
			await FetcherService.contact(contactData)
			handleToggleForm();
		}
	};
	const handleChange = (e: any) => {
		setContactData({...contactData, [e.target.name]: e.target.value})
	}

	return (
		<ModalWrapper isVisible={isVisible} handleToggleForm={handleToggleForm} setIsVisible={setIsVisible}>
			<form onChange={handleChange} ref={form}>
					<Header
						variant='h2'
						size='contactFormHeader'
						color='landingHeaderBlue'
						weight='light'
					>
						Contact form
					</Header>
					<InputWrapper>
						<StyledInput
							{...inputProps}
							placeholder='First name'
							value={contactData.firstName}
							name='firstName'
						/>
						<StyledInput
							{...inputProps}
							placeholder='Last name'
							value={contactData.lastName}
							name='lastName'
							
						/>
						<StyledInput
							{...inputProps}
							placeholder='Email address'
							type='email'
							name='email'
						/>
						<StyledInput
							{...inputProps}
							placeholder='Company name'
							name='companyName'
						/>
						<StyledInput
							{...inputProps}
							type='tel'
							placeholder='Phone number'
							name='phone'
						/>
						<FullWidthContainer>
							<StyledTextArea
								placeholder='Message'
								name='message'
								{...inputProps}
							/>
						</FullWidthContainer>
					</InputWrapper>
					<ButtonWrapper>
						<Button
							border='regular'
							borderColor='landingHeaderBlue'
							borderRadius='regular'
							fontColor='landingHeaderBlue'
							backgroundColor='transparent'
							height='navigation'
							width='navigation'
							fontWeight='extraBold'
							onClick={(e: React.MouseEvent) => {
								handleToggleForm();
								e.preventDefault();
							}}
						>
							Close
						</Button>
						<SendButton
							type='submit'
							border='regular'
							borderColor='landingHeaderBlue'
							borderRadius='regular'
							fontColor='white'
							backgroundColor='landingHeaderBlue'
							height='navigation'
							width='navigation'
							fontWeight='extraBold'
							onClick={handleSend}
						>
							Send
						</SendButton>
					</ButtonWrapper>
			</form>
		</ModalWrapper>
	);
};

// const Wrapper = styled.section<{ isVisible?: boolean }>`
// 	z-index: 3;
// 	width: 70rem;
// 	height: 46rem;
// 	margin: 0 auto;
// 	justify-content: center;
// 	flex-wrap: wrap;
// 	border-radius: ${({ theme }) => theme.borderRadius.regular};
// 	background-color: ${({ theme }) => theme.color.white};
// 	display: none;
// 	position: absolute;
// 	left: 50%;
// 	top: 50%;
// 	transform: translate(-50%, -50%);
//
// 	&.show-modal {
// 		display: block;
// 	}
// `;
//
// const ContentWrapper = styled.div`
// 	width: 62rem;
// 	margin: 0 auto;
// `;
//
// const Form = styled.div`
// 	width: 100%;
// 	margin-top: 2.4rem;
// `;

const StyledInput = styled(Input)`
	outline: 0;
`;

const StyledTextArea = styled(TextArea)`
	outline: 0;
	position: relative;
`;

const InputWrapper = styled.div`
	display: grid;
	grid-template-columns: 30rem 30rem;
	grid-gap: 2rem;
	width: 100%;
	margin-top: 2rem;
	justify-content: center;
`;

const FullWidthContainer = styled.div`
	grid-column: 1/3;
`;

// const CloseButton = styled.div`
// 	position: absolute;
// 	right: 3.5rem;
// 	top: 3rem;
// 	transform: translateY(-50%);
// 	z-index: 5;
// 	height: 2rem;
// 	width: 2rem;
// 	line-height: 4rem;
// 	text-align: center;
// 	vertical-align: middle;
// 	color: ${({ theme }) => theme.color.landingHeaderBlue};
// 	:after {
// 		content: '\\e907';
// 		font-family: ${({ theme }) => theme.font.icon};
// 		font-size: 1.6rem;
// 	}
// 	:hover {
// 		cursor: pointer;
// 	}
// `;

const ButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-top: 5.6rem;
`;

const SendButton = styled(Button)`
	margin-left: 2rem;
`;

export default ContactForm;
