import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import ContactForm from '../../molecules/ContactForm/ContactForm';
import Button from '../../atoms/Button/Button';
import { NavLink } from 'react-router-dom';


export interface ContactModalInterface {
	isFooter?: boolean
}

const ContactModal: React.FC<ContactModalInterface> = ({ isFooter }) => {
	const [isContactFormVisible, setIsContactFormVisible] = useState<boolean>(
		false
	);

	const handleToggleForm = () => {
		setIsContactFormVisible(!isContactFormVisible);
	};

	const appRoot = document.getElementById('root');
	if (appRoot === null) {
		return null;
	}
	// TODO - it should be rebuilt to use ModalWrapper component
	return (
		<div>
			{isFooter ? <StyledNavLink onClick={() => { handleToggleForm(); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }}>Contact</StyledNavLink> : <StyledButton
				border='regular'
				borderColor='white'
				borderRadius='regular'
				fontColor='white'
				backgroundColor='transparent'
				height='navigation'
				width='navigation'
				fontWeight='extraBold'
				onClick={() => {
					handleToggleForm()
					window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
				}}
			>
				Contact us
			</StyledButton>
			}

			{
				ReactDOM.createPortal(
					<ContactForm
						handleToggleForm={handleToggleForm}
						isVisible={isContactFormVisible}
						setIsVisible={setIsContactFormVisible}
					/>,
					appRoot
				)
			}
		</div>
	);
};

const StyledNavLink = styled.a`
color: ${({ theme }) => theme.color.black};
margin: 0 1.5rem;
font-size: ${({ theme }) => `${theme.font.size.paragraph.p3}rem`};
line-height: ${({ theme }) =>
		`${theme.font.lhRatio.paragraph * theme.font.size.paragraph.p3}rem`};

&:hover {
	color: ${({ theme }) => theme.color.darkBlue};
	cursor: pointer;
}
`

const StyledButton = styled(Button)`
	margin-top: 5vh;
	margin-bottom: 5vh;
`;

export default ContactModal;
