import React, { useState, useEffect, useLayoutEffect } from 'react';
import { PrivateRouteInterface } from '../types/types';
import WithLoader from '../components/molecules/WithLoader/WithLoader';
import { Redirect, useLocation, Route } from 'react-router-dom';
import { routes } from './routes';
import { verifyToken } from '../utils/verifyToken';
import { useGetEnv } from '../hooks/useGetEnv';
import { useDispatch } from 'react-redux';
import { isUserAuthenticated } from '../actions/UserActions';
import { API_URL } from '../utils/env';

const PrivateRoute: React.FC<PrivateRouteInterface> = ({
	path,
	exact,
	Component,
}) => {
	const [verifiedToken, setVerifiedToken] = useState<boolean | null>(null);
	const [isLoading, setLoading] = useState<boolean>(true);
	const location = useLocation();
	const dispatch = useDispatch();
	useEffect(() => {
		setTimeout(() => {
			if (!sessionStorage.getItem('errorMessage')) {
				if (localStorage.getItem('token')) {
					// Comment out verityToken to check token validation
					verifyToken(setVerifiedToken, setLoading, API_URL + '/');
					setVerifiedToken(true);
					dispatch(isUserAuthenticated(true));
				} else {
					// Set setVerifiedToken to false to check token validation
					setVerifiedToken(false);
					dispatch(isUserAuthenticated(false));
				}
			} else {
				verifyToken(setVerifiedToken, setLoading, API_URL + '/');
			}
		}, 100);
	}, [location.pathname]);

	useEffect(() => {
		if (verifiedToken) {
			dispatch(isUserAuthenticated(true));
			sessionStorage.removeItem('errorMessage');
		}
		else {
			dispatch(isUserAuthenticated(false));
		}
	}, [verifiedToken]);

	return (
		<Route
			path={path}
			exact={exact}
			render={props =>
				verifiedToken === null ? (
					<WithLoader isLoading={true} />
				) : verifiedToken ? (
					<>
						<Component {...props} />
					</>
				) : (
					<>
						<Redirect to={routes.landingPage} />
					</>
				)
			}
		/>
	);
};

export default PrivateRoute;
