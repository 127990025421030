import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
	ScoringItemNameInterface,
	IMOCardOptionsInterface,
} from '../../../types/types';
import Header from '../../atoms/Header/Header';
import P from '../../atoms/Paragraph/Paragraph';
import ScoringResultBox from '../../atoms/ScoringResultBox/ScoringResultBox';
import EllipsisWrapper from '../../atoms/EllipsisWrapper/EllipsisWrapper';
import Tooltip from '../Tooltip/Tooltip';
import { useSetTooltipVisibility } from '../../../hooks/useSetTooltipVisibility';
import { BoldWrapper } from '../../atoms/BoldWrapper/BoldWrapper';

const ScoringItemName: React.FC<ScoringItemNameInterface> = ({
	name,
	shipType,
	imo,
	isName = true,
	isDetails = false,
}) => {
	const shipTypeRef = useRef<HTMLParagraphElement>(null);
	const shipNameRef = useRef<HTMLDivElement>(null);
	const [isShipTypeTooltipVisible, setShipTypeTooltipVisible] = useState<
		boolean
	>(false);
	const [isShipNameTooltipVisible, setShipNameTooltipVisible] = useState<
		boolean
	>(false);
	const setTooltipVisibility = useSetTooltipVisibility();
	useEffect(() => {
		if (name) {
			setTooltipVisibility(
				shipTypeRef,
				setShipTypeTooltipVisible,
				shipType,
				'scoring-item-name-p'
			);
			setTooltipVisibility(
				shipNameRef,
				setShipNameTooltipVisible,
				name,
				'scoring-item-name-h'
			);
		}
	}, [shipTypeRef, shipNameRef]);
	return (
		<ScoringResultBox padding={'1.5rem'}>
			<Wrapper>
				{isName && (
					<Header variant='h2' size='h7' color='lightGrey' weight='light'>
						Name
					</Header>
				)}
				<div>
					{name &&
					<Tooltip
						position='right'
						content={name}
						visible={isShipNameTooltipVisible}
					>
						<div ref={shipNameRef}>
							<EllipsisWrapper linesAmount={2}>
								<Header
									variant='h3'
									size='h5'
									color={isDetails ? 'darkGrey' : 'black'}
									weight='extraBold'
									transform='uppercase'
								>
									{name}
								</Header>
							</EllipsisWrapper>
						</div>
					</Tooltip>
					}
					{isDetails
						? <IMO>IMO number: <BoldWrapper>{imo}</BoldWrapper></IMO>
						: <IMO>IMO: <BoldWrapper>{imo}</BoldWrapper></IMO>}
					<Tooltip
						position='right'
						content={shipType}
						visible={isShipTypeTooltipVisible}
						width={15}
					>
						<EllipsisWrapper linesAmount={2}>
							<StyledP ref={shipTypeRef}>{shipType}</StyledP>
						</EllipsisWrapper>
					</Tooltip>
				</div>
			</Wrapper>
		</ScoringResultBox>
	);
};

const StyledP = styled(P)<IMOCardOptionsInterface>`
	line-height: 1.7rem;
	padding-top: 0.3rem;
	color: ${({ theme, isDetails }) =>
		isDetails ? theme.color.darkGrey : theme.color.black};
`;

const IMO = styled(P)<IMOCardOptionsInterface>`
	color: ${({ theme, isDetails }) =>
		isDetails ? theme.color.darkGrey : theme.color.black};
`;

const CustomElipsisWrapper = styled(EllipsisWrapper)<IMOCardOptionsInterface>`
	color: ${({ theme }) => theme.color.darkGrey};
`;

const Wrapper = styled.div`
	display: grid;
	grid-template-rows: repeat(2, max-content);
	grid-row-gap: 0.8rem;
`;

export default ScoringItemName;
