import React from 'react';
import styled from 'styled-components';
import Logotype from '../../atoms/Logotype/Logotype';
import Navigation from '../../molecules/Navigation/Navigation';
import { routes } from '../../../routes/routes';
import { IconType, TopbarInterface } from '../../../types/types';

const headerNavigationList = [
	{ children: 'My fleet', to: routes.fleet },
	{
		children: 'Methodology',
		to: '/pdf/Methodology.pdf',
		type: 'pdf',
		target: '_blank',
	},
	{ children: 'Logout', to: routes.logout, icon: 'logout' as IconType },
];

const landingPageNavigationList = [
	{ children: 'About Us', to: routes.about },
	{ children: 'Subscription', to: routes.subscriptionModels },
	{ children: 'News', to: routes.news },
];

const Topbar: React.FC<TopbarInterface> = ({
	isLandingPage = false,
	isMaterial,
	isInfoPage = false
}) => {
	return (
		<OuterWrapper isLandingPage={isLandingPage} isMaterial={isMaterial}>
			<LandingWrapper>
				{isLandingPage ? (
					<Logotype
						isLogo={true}
						isLandingPage={isLandingPage}
						isMaterial={isMaterial}
					/>
				) : (
						<Logotype isLogo={true} isMaterial={isMaterial} isLandingPage={isLandingPage} />
					)}
				{isLandingPage ? (

					<InnerWrapper>
						<Navigation
							destination='header'
							list={landingPageNavigationList}
							isLandingPage={isLandingPage}
							isMaterial={isMaterial}
							isInfoPage={isInfoPage}
						/>
						{/* <LoginCounter /> */}
					</InnerWrapper>
				) : (
						isInfoPage ? (
							<InnerWrapper>
								<Navigation
									destination='header'
									list={landingPageNavigationList}
									isLandingPage={isLandingPage}
									isMaterial={isMaterial}
									isInfoPage={isInfoPage}
								/>
								{/* <LoginCounter /> */}
							</InnerWrapper>
						) : (
								<InnerWrapper>
									<Navigation
										destination='header'
										list={headerNavigationList}
										isLandingPage={isLandingPage}
										isMaterial={isMaterial}
										isInfoPage={isInfoPage}
									/>
									{/* <LoginCounter /> */}
								</InnerWrapper>
							)
					)}
			</LandingWrapper>
		</OuterWrapper>
	);
};

const InnerWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

const Wrapper = styled.section`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.3rem 4rem;
`;

const LandingWrapper = styled(Wrapper)`
	max-width: 145rem;
	margin: 0 auto;
`;

const OuterWrapper = styled.header<{
	isLandingPage: boolean;
	isMaterial?: boolean;
}>`
	background-color: ${({ theme, isLandingPage }) =>
		isLandingPage ? 'transparent' : theme.color.white};
	padding-top: 2rem;
`;

export default Topbar;
