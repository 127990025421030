import React, { Suspense } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Switch,
} from 'react-router-dom';
import { routes } from './routes/routes';
import Homepage from './views/Homepage/Homepage';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './themes/GlobalStyles';
import Theme from './themes/MainTheme';
import ContentHandler from './components/atoms/ContentHandler/ContentHandler';
import PrivateRoute from './routes/PrivateRoute';
import LoginRoute from './routes/LoginRoute';
import PublicRoute from './routes/PublicRoute';
import ScrollToTop from './routes/ScrollToTop';
import ManagerShips from './views/ManagerShips/ManagerShips';

const Exception = React.lazy(() => import('./views/Exception/Exception'));
const NewsPage = React.lazy(() => import('./views/NewsPage/NewsPage'));
const Scorings = React.lazy(() => import('./views/Scorings/Scorings'));
const EnvironmentalDetails = React.lazy(() =>
	import('./views/EnvironmentalDetails/EnvironmentalDetails')
);
const Subscription = React.lazy(() =>
	import('./views/Subscription/Subscription')
);
const Fleet = React.lazy(() => import('./views/Fleet/Fleet'));
const WithLoader = React.lazy(() =>
	import('./components/molecules/WithLoader/WithLoader')
);

const LandingPage = React.lazy(() => import('./views/LandingPage/LandingPage'));
const NewsView = React.lazy(() => import('./views/NewsView/NewsView'));
const Logout = React.lazy(() => import('./views/Logout/Logout'));
const About = React.lazy(() => import ('./views/About/About'));

function App() {
	return (
		<>
			<GlobalStyles />
			<ThemeProvider theme={Theme}>
				<Router>
					<ContentHandler>
						{/* TODO: Extract Topbar there when it will be unified */}
						<Suspense fallback={() => <WithLoader isLoading={true} />}>
							<ScrollToTop/>
								<Switch>
									<PublicRoute
										path={`${routes.news}/:id`}
										Component={NewsPage}
										exact
									/>
									<PublicRoute
										path={routes.about}
										Component={About}
										exact
									/>
									<PublicRoute
										path={routes.subscriptionModels}
										Component={Subscription}
										exact
									/>
									<PublicRoute path={routes.news} Component={NewsView} exact />
									<PrivateRoute
										path={routes.homepage}
										Component={Homepage}
										exact
									/>
									<PrivateRoute
										path={routes.scoring}
										Component={Scorings}
										exact
									/>
									<PrivateRoute
										path={`${routes.environmentalDetails}/:id`}
										Component={EnvironmentalDetails}
										exact
									/>
									<PrivateRoute
										path={`${routes.reliabilityDetails}/:id`}
										Component={EnvironmentalDetails}
										exact
									/>
									<PrivateRoute
										path={`${routes.sustainabilityDetails}/:id`}
										Component={EnvironmentalDetails}
										exact
									/>
									<PrivateRoute path={routes.fleet} Component={Fleet} exact />
									<PrivateRoute path={routes.logout} Component={Logout} exact />
									<PrivateRoute path={`${routes.managerShips}/:id`} Component={ManagerShips} exact />
	
									<LoginRoute
										component={LandingPage}
										path={routes.landingPage}
										exact
									/>
									<Route component={Exception} />
								</Switch>
						</Suspense>
						{/* Extract footer there when it will be unified */}
					</ContentHandler>
				</Router>
			</ThemeProvider>
		</>
	);
}

export default App;
