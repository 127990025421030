import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { routes } from './routes';

import WithLoader from '../components/molecules/WithLoader/WithLoader';
import { verifyToken } from '../utils/verifyToken';
import { useGetEnv } from '../hooks/useGetEnv';
import { useDispatch } from 'react-redux';
import { isUserAuthenticated } from '../actions/UserActions';
import { API_URL } from '../utils/env';

interface LoginRouteInterface {
	component: React.FC<{
		history: any;
		location: any;
		match: any;
		staticContext?: any;
	}>;
	path: string;
	exact?: boolean;
}

const LoginRoute: React.FC<LoginRouteInterface> = ({
	component: Component,
	path,
	exact,
}) => {
	const [verifiedToken, setVerifiedToken] = useState<boolean>(false);
	const [isLoading, setLoading] = useState<boolean>(true);
	const dispatch = useDispatch();
	const location = useLocation();
	useLayoutEffect(() => {
		setTimeout(() => {
			if (!sessionStorage.getItem('errorMessage')) {
				if (localStorage.getItem('token')) {
					// Comment out verityToken to check token validation
					verifyToken(setVerifiedToken, setLoading, API_URL+ "/");
					setVerifiedToken(true);
					dispatch(isUserAuthenticated(true));
				} else {
					// Set setVerifiedToken to false to check token validation
					verifyToken(setVerifiedToken, setLoading, API_URL + "/");
					setVerifiedToken(false);
					dispatch(isUserAuthenticated(false));
				}
			} else {
				verifyToken(setVerifiedToken, setLoading, API_URL +"/");
			}
		}, 100);
	}, [location.pathname]);

	if (isLoading) {
		return <WithLoader />;
	}
	return (
		<Route
			path={path}
			exact={exact}
			render={
				props =>
				verifiedToken ? (
					<>
						<Redirect
							to={{
								pathname: '/',
							}}
						/>
						<Component {...props} />
					</>
				) : (
					<>
						<Component {...props} />
					</>
				)
			}
		/>
	);
};

export default LoginRoute;
