import React from 'react';
import styled from 'styled-components';
import { NewsInterface } from '../../../types/types';
import Card from '../../molecules/Card/Card';

const News: React.FC<NewsInterface> = ({ list }) => (
	<Wrapper>
		{list &&
			list.map((news, index) => {
				if (index < 3) {
					return <Card key={index} {...news} />;
				}
			})}
	</Wrapper>
);

const Wrapper = styled.div`
	display: grid;
	grid-column-gap: 3rem;
	grid-template-columns: repeat(3, 1fr);
`;

export default News;
