import React from 'react';
import styled from 'styled-components';
import MainWrapper from '../../atoms/MainWrapper/MainWrapper';
import Header from '../../atoms/Header/Header';

const HeaderWithSeparator = () => (
	<MainWrapper>
		<Wrapper>
			<Header variant='h3' size='h2' align='center'>
				<>
					Transparent and easy. 200.000 scorings.
				</>
			</Header>
		</Wrapper>
	</MainWrapper>
);

const Wrapper = styled.div`
	padding-bottom: 5.4rem;
	margin: 6.3rem 0 5.4rem 0;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		left: 50%;
		bottom: 0;
		width: 67%;
		height: 1px;
		background: ${({ theme }) => theme.color.separator};
		transform: translateX(-50%);
	}
`;

export default HeaderWithSeparator;
