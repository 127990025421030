import React from 'react';
import styled from 'styled-components';
import Icon from '../../atoms/Icon/Icon';
import P from '../../atoms/Paragraph/Paragraph';
import { AdvantageItemInterface } from '../../../types/types';

const AdvantageItem: React.FC<AdvantageItemInterface> = ({
	title,
	icon,
	backgroundColor,
	padding,
	iconColor,
	color
	
}) => (
	<Wrapper
		backgroundColor={backgroundColor}
		padding={padding}
	>
		<Icon iconType={icon} color={iconColor || 'white'} size={4.2} />
		<StyledP size='p2' color={color || 'white'}>
			{title}
		</StyledP>
	</Wrapper>
);

type WrapperInterface = {
	backgroundColor?: string;
	padding?: string;
}

const Wrapper = styled.div<WrapperInterface>`
	background: ${({ theme, backgroundColor }) => backgroundColor ? backgroundColor : theme.color.darkBlue};
	padding: ${({ padding }) => padding ? padding : ''};
	display: inline-grid;
	grid-template-columns: repeat(2, max-content);
	grid-column-gap: 1.6rem;
	align-items: center;
`;

const StyledP = styled(P)`
	font-weight: 500;
`

export default AdvantageItem;
