import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import Navigation from '../../molecules/Navigation/Navigation';
import MainWrapper from '../../atoms/MainWrapper/MainWrapper';
import { routes } from '../../../routes/routes';
import { FooterInterface } from '../../../types/types';
import { useLocation } from 'react-router-dom';

const footerNavigationList = [
	{ children: 'Impressum', to: routes.impressum },
	// { children: 'Subscription models', to: routes.subscriptionModels },
	// { children: 'Contact', to: routes.contact },
];

const Footer = () => {
	const { pathname } = useLocation();
	const [staticMargin, setStaticMargin] = useState<boolean>(false);
	useLayoutEffect(() => {
		if (pathname === routes.homepage) {
			setStaticMargin(true);
		}
		return () => setStaticMargin(false);
	}, [pathname]);

	return (
		<Wrapper staticMargin={staticMargin}>
			<MainWrapper>
				<Navigation destination='footer' list={footerNavigationList} />
			</MainWrapper>
		</Wrapper>
	);
};

const Wrapper = styled.footer<FooterInterface>`
	padding: 4rem 0;
	width: 100%;
	margin-top: ${({ staticMargin }) => (staticMargin ? '3.7rem' : 0)};
	background: ${({ theme }) => theme.color.footerBackground};
	position: relative;
	z-index: 9;
`;

export default Footer;
