import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ModalWrapper from '../../molecules/ModalWrapper/ModalWrapper';
import Button from '../../atoms/Button/Button';
import Header from '../../atoms/Header/Header';
import Input from '../../atoms/Input/Input';
import { ColorType, BorderRadiusType, BorderType, NavigationDestinationType, IconType } from '../../../types/types';
import LoginComponent from '../LoginComponent/LoginComponent';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../Store';
import { useLogout } from '../../../hooks/useLogout';
import { routes } from '../../../routes/routes';
import NavigationLink from '../../atoms/NavigationLink/NavigationLink';

export interface LoginModalInterface {
	isMaterial?: boolean;
	isLandingPage?: boolean;
	isInfoPage?: boolean;
}

const LoginModal: React.FC<LoginModalInterface> = ({
	isMaterial,
	isLandingPage,
	isInfoPage,
}) => {
	const [isFormVisible, setIsFormVisible] = useState<boolean>(false);

	const userState = useSelector((state: RootStore) => state.user);

	const logout = useLogout();

	const handleToggleForm = () => {
		setIsFormVisible(!isFormVisible);
	};

	const handleLogout = () => {
		logout();
	};

	const appRoot = document.getElementById('root');
	if (appRoot === null) {
		return null;
	}

	return (
		<>
			{isMaterial ? (
				isLandingPage ? (
					localStorage.getItem('token') && userState.isAuthenticated ? (
						<StyledButton
							border='regular'
							borderColor='white'
							borderRadius='regular'
							fontColor='white'
							backgroundColor='transparent'
							height='navigation'
							width='navigation'
							fontWeight='extraBold'
							onClick={() => {
								handleLogout();
								window.location.reload();
							}}
						>
							logout
						</StyledButton>
					) : (
							<StyledButton
								border='regular'
								borderColor='white'
								borderRadius='regular'
								fontColor='white'
								backgroundColor='transparent'
								height='navigation'
								width='navigation'
								fontWeight='extraBold'
								onClick={() => handleToggleForm()}
							>
								login
							</StyledButton>
						)
				) : (
						isInfoPage ? (
							userState.isAuthenticated ? (
								<NavigationLink
									isLastLink={true}
									destination={'header' as NavigationDestinationType}
									children={'Logout'}
									to={routes.logout}
									icon={'logout' as IconType}
								/>
							) :
								(
									<StyledButton
										border='regular'
										borderColor='landingHeaderBlue'
										borderRadius='regular'
										fontColor='landingHeaderBlue'
										backgroundColor='transparent'
										height='navigation'
										width='navigation'
										fontWeight='extraBold'
										onClick={() => handleToggleForm()}
									>
										login
									</StyledButton>
								)
						) : null)
			) : null}
			{
				ReactDOM.createPortal(
					<LoginComponent
						handleToggleForm={handleToggleForm}
						isVisible={isFormVisible}
						setIsVisible={setIsFormVisible}
					/>,
					appRoot
				)
			}
		</>
	);
};

const StyledButton = styled(Button)`
	margin-left: 2.2rem;
`;

export default LoginModal;
