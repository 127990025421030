import React from 'react';
import styled from 'styled-components';
import MainWrapper from '../../atoms/MainWrapper/MainWrapper';
import Search from '../../molecules/Search/Search';
import Header from '../../atoms/Header/Header';

export interface HeroInterface {
	isSearchEngine?: boolean;
	headerText?: string;
}

const Hero: React.FC<HeroInterface> = ({
	isSearchEngine = true,
	headerText,
}) => {
	const handleChange = (value: string) => {};
	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
	};
	return (
		<>
		<InnerWrapper>
			<StyledMainWrapper>
				<Content>
					<HeroHeader>
						{headerText ? (
							<Header
								variant='h2'
								size='heroAlternativeText'
								align='center'
								color='white'
							>
								{headerText}
							</Header>
						) : (
							<>
								<Header variant='h1' size='h1' align='center' color='white'>
									Everything about ships
								</Header>
								<Header variant='h3' size='h3' align='center' color='white'>
									More than 70.000 ships
								</Header>
								<Header variant='h3' size='h3' align='center' color='white'>
									Reliability - Environmental - Sustainability (ESG)
								</Header>
							</>
						)}
					</HeroHeader>
					<SearchWrapper>
						{isSearchEngine && (
							<Search
								buttonContent='Search'
								placeholder='Search by Name, search by IMO number'
								onChange={value => handleChange(value)}
								onSubmit={handleSubmit}
							/>
						)}
					</SearchWrapper>
				</Content>
			</StyledMainWrapper>
		</InnerWrapper>
		<HeroImage/>
	</>
	);
};

const SearchWrapper = styled.div`
	max-width: 66rem;
	margin: 0 auto;
	height: 5.6rem;
	z-index: 2; //Above skewed rectangle
`;

const HeroHeader = styled.header`
	padding-top: 5rem;
	display: grid;
	grid-template-rows: repeat(3, max-content);
	grid-row-gap: 2rem;
`;

const Content = styled.div`
	display: grid;
	grid-template-rows: repeat(2, max-content);
	grid-row-gap: 5rem;
`;

const StyledMainWrapper = styled(MainWrapper)`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const InnerWrapper = styled.section`
	flex: 4;
	background-color: ${({theme}) => theme.color.lightBlue};
	background-size: cover;
	background-position: center;
	position: relative;
	
	&::after {
	 	content: "";
		width: 100%;
		height: 15rem;
		position: absolute;
		bottom: -6rem;
		right: left;
		background: ${({theme}) => theme.color.lightBlue};
		transform: skewY(3deg);
	}
`;

const HeroImage = styled.img`
	flex: 5;
	width: 100%;
	background-image: url('/images/header-background.jpg');
	background-size: cover;
	background-position-y: 45%;
	background-repeat: no-repeat;
`

export default React.memo(Hero);
