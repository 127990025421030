export const useSetTooltipVisibility = () => (
	ref: any,
	cb: (value: boolean) => void,
	text: string,
	className: string
) => {
	if (ref.current) {
		const el = document.createElement('div');
		el.innerText = text;
		el.style.width = `${ref.current.offsetWidth}px`;
		el.classList.add(className);
		ref.current.appendChild(el);
		cb(Math.abs(ref.current?.offsetHeight - el.offsetHeight) > 1);
		el.remove();
	}
};
