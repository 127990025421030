import {
	ADD_TO_MANAGER_SHIPS,
	SET_MANAGER_SHIPS_URL,
	ADD_TO_FLEET, BLOCK_BUTTON, FAIL,
	FLEET, PAGINATION_LOADING_OFF, PAGINATION_LOADING_ON,
	SHIP_FAIL,
	SHIP_LOADING,
	SHIP_SAVING_ID,
	SHIP_SAVING_ID_FAIL,
	SHIP_SUCCESS,
	ShipDispatchTypes,
	SUCCESS, UNBLOCK_BUTTON,
	UPDATE_SHIP,
} from '../actions/ShipActionTypes';
import { API_URL } from '../utils/env';
import { MyFleetShipResponse } from '../types/types';

interface DefaultStateInterface {
	loading: boolean;
	ship?: any;
	managerShips?: any;
	nextManagerShipsUrl?: string;
	id?: number;
	fleet?: MyFleetShipResponse[];
	nextFleetUrl: string;
	nextVesselsUrl?: string;
	paginationLoading: boolean;
	buttonsBlocked: number[];
}

const defaultState: DefaultStateInterface = {
	loading: false,
	paginationLoading: false,
	nextFleetUrl:`${API_URL}/api/fleets/`,
	nextManagerShipsUrl: '',
	nextVesselsUrl: '',
	buttonsBlocked: []
};

const shipReducer = (
	state: DefaultStateInterface = defaultState,
	action: ShipDispatchTypes
): DefaultStateInterface => {
	switch (action.type) {
		case SHIP_FAIL:
			return {
				...state,
				loading: false,
			};
		case SHIP_LOADING:
			return {
				...state,
				loading: true,
			};
		case SUCCESS:
			return {
				...state,
				loading: false,
			};
		case PAGINATION_LOADING_ON:
			return {
				...state,
				paginationLoading: true,
			};
		case PAGINATION_LOADING_OFF:
			return {
				...state,
				paginationLoading: false,
			};
		case SHIP_SUCCESS:
			return {
				...state,
				loading: false,
				ship: action.payload,
			};
		case SHIP_SAVING_ID_FAIL:
			return {
				...state,
				loading: true,
			};
		case SHIP_SAVING_ID:
			return {
				...state,
				loading: false,
				id: action.payload,
			};
		case BLOCK_BUTTON:
			return {
				...state,
				buttonsBlocked: state.buttonsBlocked.concat(action.payload),
			}
		case UNBLOCK_BUTTON:
			return {
				...state,
				buttonsBlocked: state.buttonsBlocked.filter(shipId => shipId !== action.payload),
			}
		case FLEET:
			return {
				...state,
				// loading: true,
				fleet: action.payload,
				// nextFleetUrl: action.nextUrl
			};
		case ADD_TO_FLEET:
			return {
				...state,
				// loading: true,
				fleet: state.fleet?.concat(action.payload)?? action.payload,
				// nextFleetUrl: action.nextUrl
		}
		case ADD_TO_MANAGER_SHIPS:
			return {
				...state,
				managerShips: state.managerShips?.concat(action.payload)?? action.payload,
				nextManagerShipsUrl: action.nextUrl
			}
		case SET_MANAGER_SHIPS_URL:
			return {
				...state,
				managerShips: [],
				nextManagerShipsUrl: action.payload
			}
		case UPDATE_SHIP:
			return {
				...state,
				ship: {
					...state.ship,
					...action.payload
				}
			}
		case FAIL:
			return {
				...state,
				loading: false
			}
		default:
			return state;
	}
};

export default shipReducer;
