import React, { useState } from 'react';
import styled from 'styled-components';
import {
	SingleShipResponseInterface,
} from '../../../types/types';
import Button from '../../atoms/Button/Button';
import ScoringItemName from '../ScoringItemName/ScoringItemName';
import ScoringDetails from '../ScoringDetails/ScoringDetails';
import Paper from '../../atoms/Paper/Paper';
import ScoringHighlitedInformation from '../ScoringHighlitedInformation/ScoringHighlitedInformation';
import Image from '../../atoms/Image/Image';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../routes/routes';
import { API_URL } from '../../../utils/env';
import { FetcherService } from '../../../utils/FetcherService';
import { toggleShipMyFleet } from '../../../actions/ShipActions';
import { useSelector, useDispatch } from 'react-redux';
import { RootStore } from '../../../Store';
import CenterSpinner from '../../atoms/CenterSpinner/CenterSpinner';
import { keysToSnake } from '../../../utils/stringUtils';
import { UNBLOCK_BUTTON } from '../../../actions/ShipActionTypes';
const ScoringResultItemNew: React.FC<
	SingleShipResponseInterface
> = (props) => {
	const {
	id,
	name,
	thumbnail,
	yearBuild,
	grossTonnage,
	deadweight,
	length,
	imoNumber,
	shipType,
	scores,
	isInMyFleet,
	fleetShipId,
	flagCountry,
} = props
	const history = useHistory();
	const scoringItemNameArgs = { name, imo: imoNumber, shipType: shipType, isInMyFleet, fleetShipId, flagCountry };

	const detailsArgs = [
		{ name: 'Built', value: yearBuild },
		{ name: 'GT', value: grossTonnage },
		{ name: 'DWT', value: deadweight },
		{ name: 'Length o.a.', value: `${parseFloat(length).toFixed(1)} m` },
		{ name: 'Flag', value: flagCountry },
	];
	
	const [inMyFleet, setInMyFleet] = useState(isInMyFleet);

	const shipStore = useSelector((store: RootStore) => store.ship)
	const buttonsBlocked = shipStore.buttonsBlocked;
	
	const loading = !!buttonsBlocked.find(shipId => shipId === id);
	const dispatch = useDispatch();
	const toggleMyFleet = () => {
		const shipData = keysToSnake(props);
		FetcherService.checkIfInMyFleet([id]).then(res => {
			toggleShipMyFleet(dispatch, id, res[id].is_in_my_fleet, res[id].fleet_id, shipData)
				.then(response => {
					if (response.state === 'added') {
						setInMyFleet(true)
					}
					else if (response.state === 'removed'){
						setInMyFleet(false)
					}
				})
				.catch(err => {
					console.log('err', err)
					dispatch({type: UNBLOCK_BUTTON, payload: id})
					}
				);
		})
	}
	return (
		<Paper>
			<Wrapper>
				<Image src={thumbnail? API_URL + thumbnail : ''} alt={name} ratio={0} />
				<ScoringItemName {...scoringItemNameArgs} />
				<ScoringHighlitedInformation
					environmentalScore={scores.environmentalScore}
					reliabilityScore={scores.reliabilityScore}
					sustainabilityScore={scores.sustainabilityScore}
				/>
				<ScoringDetails details={detailsArgs} />
				<ButtonWrapper>
					<ButtonInnerWrapper>
						<Button
							backgroundColor='lightBlue'
							fontColor='white'
							height='small'
							width='small'
							fontSize='small'
							onClick={() =>
								history.push(`${routes.environmentalDetails}/${id}`)
							}
						>
							more info
						</Button>
						{!loading  &&
							<Button
								backgroundColor={loading? 'grey' : 'white'}
								fontColor='black'
								height='small'
								width='small'
								fontSize='small'
								border='regular'
								disabled={loading}
								onClick={toggleMyFleet}
							>
								{inMyFleet === false ?  'Add to my fleet': 'Remove from my fleet '}
							</Button>
						}
						<CenterSpinner loading={loading}/>
					</ButtonInnerWrapper>
				</ButtonWrapper>
			</Wrapper>
		</Paper>
	);
};

const ButtonInnerWrapper = styled.div`
	display: grid;
	grid-template-rows: repeat(2, max-content);
	grid-row-gap: 0.8rem
`;

const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
`;

const Wrapper = styled.div`
	height: 15.14rem;
	background: ${({ theme }) => theme.color.white};
	display: grid;
	/* grid-column-gap: 1.5rem; */
	grid-template-columns:
		repeat(2, calc(100% / 6)) calc(100% / 3)
		repeat(2, calc(100% / 6));
	/* grid-template-columns:
		repeat(2, calc((100% - 6rem) / 6)) calc((100% - 6rem) / 3)
		repeat(2, calc((100% - 6rem) / 6)); */
`;

export default ScoringResultItemNew;
