import React from 'react';
import styled from 'styled-components';
import {
	HeaderComponentInterface,
	HeaderInterface,
} from '../../../types/types';

const Header: React.FC<HeaderInterface> = ({ children, variant, ...args }) => (
	<StyledTitle as={variant} {...args}>
		{children}
	</StyledTitle>
);

const StyledTitle = styled.h1<HeaderComponentInterface>`
	color: ${({ theme, color }) => (color ? theme.color[color] : 'inherit')};

	font-size: ${({ theme, size }) =>
		size
			? `${theme.font.size.title[size]}rem`
			: `${theme.font.size.title.h1}rem`};

	line-height: ${({ theme, size }) =>
		size
			? `${theme.font.size.title[size] * theme.font.lhRatio.title}rem`
			: `${theme.font.size.title.h1 * theme.font.lhRatio.title}rem`};

	text-transform: ${({ theme, transform }) =>
		transform ? theme.text.transform[transform] : theme.text.transform.left};

	font-weight: ${({ theme, weight }) =>
		weight ? theme.font.weight[weight] : theme.font.weight.bold};

	text-align: ${({ theme, align }) =>
		align ? theme.text.align[align] : theme.text.align.left};

	text-shadow: ${({ theme, shadow }) =>
		shadow ? theme.text.shadow.header : 'unset'};
`;

export default Header;
