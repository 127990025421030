import React from 'react';
import styled from 'styled-components';
import MainWrapper from '../../atoms/MainWrapper/MainWrapper';
import { EmptyContentInterface } from '../../../types/types';
import Header from '../../atoms/Header/Header';
import { Link } from 'react-router-dom';
import { routes } from '../../../routes/routes';

const EmptyContent: React.FC<EmptyContentInterface> = ({
	children = 'There is nothing to show you at this moment.',
}) => (
	<MainWrapper role='alert'>
		<Wrapper>
			<Header variant='h2' size='h4' color='alertColor' weight='regular'>
				{children} <StyledLink to={routes.homepage}>Go to homepage.</StyledLink>
			</Header>
		</Wrapper>
	</MainWrapper>
);

const StyledLink = styled(Link)`
	color: ${({ theme }) => theme.color.alertColor};
	font-weight: ${({ theme }) => theme.font.weight.medium};
	text-decoration: underline;
`;

const Wrapper = styled.aside`
	background: ${({ theme }) => theme.color.alertBackground};
	padding: 1.8rem;
	border-radius: 0.4rem;
`;

export default EmptyContent;
