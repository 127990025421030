import React from 'react';
import AdvantagesBar from '../../components/organisms/AdvantagesBar/AdvantagesBar';
import HeaderWithSeparator from '../../components/organisms/HeaderWithSeparator/HeaderWithSeparator';
import News from '../../components/organisms/News/News';
import Hero from '../../components/organisms/Hero/Hero';
import Topbar from '../../components/organisms/Topbar/Topbar';
import Footer from '../../components/organisms/Footer/Footer';
import MainWrapper from '../../components/atoms/MainWrapper/MainWrapper';
import styled from 'styled-components';

export const newsList = [
	{
		id: 1,
		title: 'ESG Compliance',
		lead: "Sustainability and Governance in shipping? Deal with it, or loose the future.",
		image: '/images/article_1.jpg',
		imageAlt: 'Ship image',
		content: `<p>According to the UN Sustainable development goals initiative, a 17-step action plan should be followed and implemented in the maritime industry. One of the plan&rsquo;s objectives is to improve the collective mindset to ensure we protect and take care of our planet for future generations.</p>
			<p><br />Where does the shipping mindset stand on the issue of SUSTAINABILITY?</p>
			<p>Internationally, there have been a number of initiatives:</p>
			<p>&nbsp;</p>
			<ul>
			<li style="list-style-type: disc;">A resolution adopted by the UN General Assembly in 2018 set steps for <br/><a style="text-decoration: underline; color: blue;" href="https://www.imo.org/en/MediaCentre/HotTopics/Pages/Reducing-greenhouse-gas-emissions-from-ships.aspx">&lsquo; Transforming our world: the 2030 Agenda for sustainable development&rsquo;</a></li>
			<li style="list-style-type: disc;">Many carriers, charterers and shippers, as well as those providing finance, demand a sustainability mindset of ship owners and managers and require specific evidence of the sustainabilitly of operations of any specific ship.</li>
			</ul>
			<p>&nbsp;</p>
			<p><br />With the UN initiative to the fore, as well as growing industry demand in mind, our &lsquo;Sustainability/Environmental, Social and Corporate Governance (ESG) Score&rsquo; offers all parties a clear idea of the efforts being made by shipowners and ship managers to ensure ships contribute to a better and more sustainable planet.</p>
			<p><br />The objective of the &lsquo;Score&rsquo; is to benefit those working towards &ldquo;a world in which every country enjoys sustained, inclusive and sustainable economic growth and decent work for all.&rdquo;</p>`,
	},
	{
		id: 2,
		title: 'Reliability',
		lead: 'Safety compliance and reliability finally becomes transparent',
		image: '/images/article_3.jpg',
		imageAlt: 'Article image 2',
		content: `<p>One the most important factors in the maritime industry is to ensure that a ship is available for its commercial activities at all times.</p>
			<p><br />In order to meet commercial obligations, ships must always comply with international regulations and ensure high levels of safety onboard and towards the marine environment. Above all, ships must permanently be in superb technical condition to avoid any systems&rsquo; failures and casualties and to ensure that they will be available when needed.</p>
			<p><br />Our system takes into consideration technical aspects of ships including age, Port State Control, its casualty record and Document of Compliance (DOC) performance to evaluate any possibility of the ship failing to maintain the maritime industry&rsquo;s high standards.</p>
			<p><br />Accordingly, our &lsquo;Reliability Score&rsquo; has been designed to benefit all ships that maintain records of excellence during external inspections, and operate under the most reputable flags and classification societies in the<br />world.</p>`,
	},
	{
		id: 3,
		title: 'Environmental impact',
		lead: 'We go beyond CO emissions. What is the real environmental impact of each vessel?',
		image: '/images/article_2.jpg',
		imageAlt: 'Article image 3',
		content: `<p>Ships have the potential to carry huge volumes of goods from one point to another. According to the International Chamber of Shipping (ICS), the shipping industry is responsible for around 90% of the world&rsquo;s traded cargoes.</p>
			<p><br />With air pollution studies becoming increasingly pertinent, the shipping industry has acted quickly to implement measures to reduce greenhouse gas emissions. In 2018, the International Maritime Organization (IMO) adopted an Initial Strategy to reduce greenhouse gases emissions, the objective being to reduce at least 50% of CO2 emissions per transport by 2050 compared to 2008, with the aim of increasing this to 70%. However, since importance of measures against global warming is raising continuously further initiatives have been initiated. Only a few examples are:</p>
			<p>&nbsp;</p>
			<ul>
			<li style="list-style-type: disc;">EU Commission proposal to include shipping in EU Emission Trade System (ETS);</li>
			<li style="list-style-type: disc;">Short term IMO emissions plan based on three key pillars to be decided within upcoming IMO&rsquo;s MEPC working group:<br />&nbsp;&nbsp;&nbsp; o EEXI (Energy Efficiency Existing Ship Index);<br />&nbsp;&nbsp;&nbsp; o CII (Carbon Intensity Indicator) and;<br />&nbsp;&nbsp;&nbsp; o Strengthened SEEMP (Shipboard Energy Efficiency Management Plans);</li>
			<li style="list-style-type: disc;">Commitment from commodity traders and charterers as well as ship owner associations for
							decarbonisation in shipping.</li>
			</ul>
			<p>&nbsp;</p>
			<p><br />Our &lsquo;Environmental Score&rsquo; has been designed to take into account the short, medium and long-term measures. It aims to benefit all ship managers and shipowners actively seeking and implementing measures for greener shipping.</p>`
	},
];

const Homepage: React.FC = () => {
	// const userState = useSelector((state: RootStore) => state.user);
	// useEffect(() => {
	// 	console.log(userState);
	// });

	return (
		<>
			<ScreenWrapper>
				<Topbar isMaterial={true}/>
				<Hero />
			</ScreenWrapper>
			<AdvantagesBar />
			<HeaderWithSeparator />
			<MainWrapper>
				<News list={newsList} />
			</MainWrapper>
			<Footer />
		</>
	);
};

const ScreenWrapper = styled.div`
	height: 100vh;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: hidden;
`

export default Homepage;
