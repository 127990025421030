import Axios from 'axios';

type verifyTokenType = (
	setVerified: (data: boolean) => void,
	setLoading: (data: boolean) => void,
	url?: string
) => void;

export const verifyToken: verifyTokenType = (setVerified, setLoading, url) => {
	setLoading(true);
	Axios.post(`${url}api/token/verify/`, {
		token: localStorage.getItem('token'),
	})
		.then(() => {
			setVerified(true);
			setLoading(false);
		})
		.catch(() => {
			console.log('error when veryfing token')
			Axios.post(`${url}api/refresh/`, {
				refresh: localStorage.getItem('refresh'),
			})
				.then(({ data }) => {
					console.log('refreshing token success')
					localStorage.setItem('token', data.access);
					setVerified(true);
					setLoading(false);
				})
				.catch(() => {
					console.log('refreshing token failed')
					localStorage.removeItem('token');
					localStorage.removeItem('refresh');
					setVerified(false);
					setLoading(false);
				});
		});
};
