import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../../components/atoms/Header/Header';
import MainWrapper from '../../components/atoms/MainWrapper/MainWrapper';
import Footer from '../../components/organisms/Footer/Footer';
import ScoringResultsNew from '../../components/organisms/ScoringResults/ScoringResultsNew';
import Topbar from '../../components/organisms/Topbar/Topbar';
import WithLoader from '../../components/molecules/WithLoader/WithLoader';
import { useDispatch } from 'react-redux';
import useManagerShips from '../../hooks/useManagerShips';
import { useLocation } from 'react-router-dom';
import { SET_MANAGER_SHIPS_URL } from '../../actions/ShipActionTypes';
import { API_URL } from '../../utils/env';
import CenterSpinner from '../../components/atoms/CenterSpinner/CenterSpinner';


const ManagerShips = () => {
	
	const dispatch = useDispatch();
	const location = useLocation();

	const {
		shipsData,
		fetchNext,
		loading
	} = useManagerShips()
	
	const onScroll = () => {
		const d = document.documentElement;
		const offset = d.scrollTop + window.innerHeight;
		const height = document.body.scrollHeight;
		if (offset + 200 >= height) {
			fetchNext();
		}
	}
	
	useEffect(() => {
		const managerId = location.pathname.split('/').pop()
		dispatch({
			type: SET_MANAGER_SHIPS_URL,
			payload: `${API_URL}/api/ships/?ism_manager=${managerId}&page_size=5`
		})
		fetchNext()
	}, [location.pathname])
	
	useEffect(() => {
		window.addEventListener("scroll", onScroll);
		return () => {
		  window.removeEventListener("scroll", onScroll);
		};
  }, []);
	
	return (
		shipsData.length === 0 ? <WithLoader isLoading={true} />:
		<>
		<Topbar isMaterial={true}/>
		<MainWrapper>
			<Wrapper>
				<Header variant='h2' size='h2' transform='uppercase'>
					Manager Ships
				</Header>
				<ScoringResultsNew data={shipsData}/>
				<CenterSpinner loading={loading}/>
			</Wrapper>
		</MainWrapper>
		<Footer/>
	</>)
};

const Wrapper = styled.section`
	padding: 3.7rem 0 8.1rem 0;
	display: grid;
	grid-template-rows: repeat(2, max-content);
	grid-row-gap: 3.7rem;
`;

export default ManagerShips;
