import styled from 'styled-components';

const OptionsList = styled.div`
	left: 0;
	top: calc(100% + 1rem);
	width: 100%;
	z-index: 5;
	background: ${({ theme }) => theme.color.white};
	display: none;
	max-height: 20rem;
	overflow: auto;

	::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 10px;
		background-color: ${({ theme }) => theme.color.scrollBackground};
	}

	::-webkit-scrollbar {
		width: 8px;
		background-color: ${({ theme }) => theme.color.scrollBackground};
	}

	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: ${({ theme }) => theme.color.searchGrey};
	}
`;

export default OptionsList;
