import { SingleShipResponseInterface } from '../types/types';
import { RootStore } from '../Store';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FetchNextManagerShipsPage } from '../actions/ShipActions';


const useManagerShips = () => {
    
    const [shipsData, setShipsData] = useState<SingleShipResponseInterface[]>([]);
    
    const shipStore = useSelector((store: RootStore) => store.ship);
    const managerShips = shipStore.managerShips
    const loading = shipStore.loading;

    const dispatch = useDispatch();
    
    const fetchNext = () => {
        dispatch(FetchNextManagerShipsPage())
    }
    
    useEffect(() => {
        if (!managerShips) {
            dispatch(FetchNextManagerShipsPage());
        } else {
            setShipsData(managerShips.map((el: any) => ({
                id: el.id,
                image: el.thumbnail,
                name: el.name,
                imoNumber: el.imo_number,
                shipType: el.ship_type,
                scores: el.scores,
                yearBuild: el.year_build,
                grossTonnage: el.gross_tonnage || 0,
                deadweight: el.deadweight,
                length: el.length,
                isInMyFleet: el.is_in_my_fleet,
                thumbnail: el.thumbnail,
                flagCountry: el.flag_country
            })));
        }
    }, [managerShips])
    
    return { shipsData, fetchNext, loading }
}

export default useManagerShips;

