import {
	UserDispatchTypes,
	USER_UNAUTHENTICATED,
	USER_AUTHENTICATED,
	USER_LOADING,
} from '../actions/UserActionTypes';

interface DefaultStateInterface {
	loading: boolean;
	isAuthenticated: boolean;
	id?: string;
}

const defaultState: DefaultStateInterface = {
	loading: false,
	isAuthenticated: false,
};

const shipReducer = (
	state: DefaultStateInterface = defaultState,
	action: UserDispatchTypes
): DefaultStateInterface => {
	switch (action.type) {
		case USER_UNAUTHENTICATED:
			return {
				...state,
				loading: false,
			};
		case USER_LOADING:
			return {
				...state,
				loading: true,
			};
		case USER_AUTHENTICATED:
			return {
				...state,
				loading: false,
				isAuthenticated: action.payload,
			};
		default:
			return state;
	}
};

export default shipReducer;
