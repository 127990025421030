import React from 'react';
import styled from 'styled-components';
import ListItem from '../../atoms/ListItem/ListItem';
import { UnorderedListInterface } from '../../../types/types';

const UnorderedList: React.FC<UnorderedListInterface> = ({ items, color }) => (
	<UlList>
		{items.map((item, index) => (
			<ListItem key={index} color={color}>
				{item}
			</ListItem>
		))}
	</UlList>
);

const UlList = styled.ul`
	list-style: disc;
	margin-left: 4rem;
`;

export default UnorderedList;
