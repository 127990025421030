import styled from 'styled-components';
import { EllipsisWrapperInterface } from '../../../types/types';

const EllipsisWrapper = styled.div<EllipsisWrapperInterface>`
	display: -webkit-box;
	-webkit-line-clamp: ${({ linesAmount }) => linesAmount};
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
`;

export default EllipsisWrapper;
