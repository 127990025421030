import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { SpinnerInterface } from '../../../types/types';
import MainTheme from '../../../themes/MainTheme';

const Spinner: React.FC<SpinnerInterface> = ({
	isLoading,
	size = 35,
	color = MainTheme.color.lightBlue,
}) => <ClipLoader size={size} color={color} loading={isLoading} />;

export default Spinner;
