import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import { SearchInterface } from '../../../types/types';
import OptionsList from '../../atoms/OptionsList/OptionsList';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../routes/routes';
import { FetcherService } from '../../../utils/FetcherService';
import P from '../../atoms/Paragraph/Paragraph';
import { BoldWrapper } from '../../atoms/BoldWrapper/BoldWrapper';

interface ListItem {
	name: string;
	imo_number: string;
}

export interface SuggestionInterface {
	[query: string]: ListItem[]
}

const Search: React.FC<SearchInterface> = ({
	placeholder,
	buttonContent,
	onSubmit,
}) => {
	const [inputValue, setInputValue] = useState<string>('');
	const [editedInput, setEditedInput] = useState(false);
	const [searchQuery, setSearchQuery] = useState<string | null>(null);
	const [suggestions, setSuggestions] = useState<SuggestionInterface | null>();
	
	const wrapperRef = useRef<HTMLFormElement | null>(null);
	const history = useHistory();

	const handleLocalChange = (e: any) => {
		const target = e.target as HTMLTextAreaElement;
		const value = target.value;
		setInputValue(value)
		if (target.value.length > 0) {
			setEditedInput(true);
		} else {
			setEditedInput(false);
		}
	};

	const handleFocus = (e: any) => {
		if (!editedInput) {
			const target = e.target as HTMLTextAreaElement;
			const value = target.value;
			setInputValue(value);
			setEditedInput(true);
		}
	};

	const handleSelectOption = (item: ListItem) => {
		setInputValue(item.name + ' ' + item.imo_number);
		setSearchQuery(item.imo_number);
		setEditedInput(false);
	};

	useEffect(() => {
		if (inputValue.length === 0) {
			setSuggestions(null);
		} else {
			const delayDebounce = setTimeout(() => {
				inputValue.length >= 3 &&
				FetcherService.getAutocompleteSuggestions(inputValue).then(
					response => {
						const query = response[0];
						const data = JSON.parse(response[1]);
						// Problem with multiple requests on typing. We must to make sure that response
						// matches inputs query.
						setSuggestions(prevState => ({...prevState, [query]: data.suggestions}))
					}
				)
			}, 400);
			return () => clearTimeout(delayDebounce);
		}
	}, [inputValue]);

	const handleSearchShip = () => {
		history.push({
			pathname: routes.scoring,
			search: `?search=${searchQuery}`
		})
	};

	return (
		<Form onSubmit={onSubmit} ref={wrapperRef} autoComplete={'off'}>
			<StyledInput
				placeholder={placeholder}
				border='unset'
				icon='search_thin'
				iconWeight='bold'
				name='search'
				onClick={e => handleFocus(e)}
				onChange={(e: React.FormEvent<HTMLInputElement>) => {
					setSearchQuery(e.currentTarget.value);
					handleLocalChange(e);
				}}
				value={inputValue}
			/>
			<StyledButton
				backgroundColor='searchNavyBlue'
				fontColor='white'
				height='regular'
				width='regular'
				onClick={handleSearchShip}
				disabled={!!editedInput}
			>
				{buttonContent}
			</StyledButton>
			{editedInput && (
				<StyledOptionsList
					data-id='autocomplete-list'
				>
					{suggestions &&  suggestions[inputValue] && suggestions[inputValue].length > 0 && (
						<>
							<ResultsWrapper>
								{suggestions[inputValue].map((item: ListItem) => (
									<OptionItem
										key={item.imo_number}
										onClick={() => {
											handleSelectOption(item);
										}}
									>
										<P size='p4' color='cardContentBlack'>
											Name: <BoldWrapper>{item.name}</BoldWrapper> IMO Number:
											<BoldWrapper> {item.imo_number}</BoldWrapper>
										</P>
									</OptionItem>
								))}
							</ResultsWrapper>
						</>
					)}
				</StyledOptionsList>
			)}
		</Form>
	);
};

const ResultsWrapper = styled.div`
	min-height: 18rem;
	border: ${({ theme }) => theme.border.unset};
	margin-top: -0.23rem;
`;

const StyledInput = styled(Input)`
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
`;

const StyledButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
`;

const Form = styled.form`
	display: inline-grid;
	grid-template-columns: 46.3rem max-content;
	align-items: center;
	border-radius: 0.8rem;
	overflow: hidden;
`;

const StyledOptionsList = styled(OptionsList)<{ isListLoading?: boolean }>`
	display: block;
	text-align: ${({ isListLoading }) => (isListLoading ? 'center' : 'left')};
	margin-top: -0.23rem;
	border-radius: ${({ theme }) => theme.borderRadius.onlyBottom};
`;

const OptionItem = styled.div`
	padding: 0.5rem 3rem 0.5rem 1rem;
	user-select: none;
	transition: background 0.3s ease-in-out;
	height: 3.2rem;
	&:hover {
		background-color: ${({ theme }) => theme.color.searchGrey};
	}

	* {
		pointer-events: none;
	}

	&.selected {
		background: green;
		cursor: pointer;
		& > * {
			color: ${({ theme }) => theme.color.white};
		}
	}

	:not(.selected):hover {
		background: ${({ theme }) => theme.color.mainTransparent};
		cursor: pointer;
	}
`;

export default Search;
