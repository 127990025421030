import React from 'react';
import styled from 'styled-components';
import NavigationLink from '../../atoms/NavigationLink/NavigationLink';
import {
	NavigationInterface,
	NavigationLinkComponentInterface,
} from '../../../types/types';
import { useLocation } from 'react-router-dom';
import Button from '../../atoms/Button/Button';
import LoginModal from '../../organisms/LoginModal/LoginModal';

const Navigation: React.FC<NavigationInterface> = ({
	list,
	destination,
	isLandingPage,
	isMaterial,
	isInfoPage
}) => {
	const { pathname } = useLocation();
	return (
		<nav>
			<StyledUl isHeaderVersion={destination === 'header'}>
				<>
					{list.map(
						({ children, to, icon, restrictedPages, type, target }, index) => {
							if (
								restrictedPages &&
								!restrictedPages.includes(`/${pathname.split('/')[1]}`)
							) {
								return null;
							}
							return (
								<NavigationLink
									type={type}
									target={target}
									icon={icon}
									to={to}
									key={children}
									destination={destination}
									isLastLink={list.length === index + 1}
									isLandingPage={isLandingPage}
									isMaterial={isMaterial}
								>
									{children}
								</NavigationLink>
							);
						}
					)}
					<LoginModal isMaterial={isMaterial} isLandingPage={isLandingPage} isInfoPage={isInfoPage} />
				</>
			</StyledUl>
		</nav>
	);
};

const StyledUl = styled.ul<NavigationLinkComponentInterface>`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	background: ${({ theme, isHeaderVersion }) =>
		isHeaderVersion ? 'inherit' : theme.color.footerBackground};
`;

const StyledButton = styled(Button)`
margin - left: 2.2rem;
`;
export default Navigation;
