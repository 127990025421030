

export const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const snakeToCamel = (str: string) =>   {
    return str.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
            .replace('-', '')
            .replace('_', '');
    });
}

const isObject = (obj: any) => {
  return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function';
};

export const keysToCamel = (obj: {[key: string]: any}): any => {
  if (isObject(obj)) {
    const n: {[key: string]: any} = {};

    Object.keys(obj)
      .forEach((k) => {
        n[snakeToCamel(k)] = keysToCamel(obj[k]);
      });

    return n;
  } else if (Array.isArray(obj)) {
    return obj.map((i) => {
      return keysToCamel(i);
    });
  }
  
  return obj;
};

export const camelToSnake = (string: string) => {
       return string.replace(/[\w]([A-Z])/g, function(m) {
           return m[0] + "_" + m[1];
       }).toLowerCase();
   }
   
export const keysToSnake =  (obj: {[key: string]: any}): any => {
     if (isObject(obj)) {
    const n: {[key: string]: any} = {};

    Object.keys(obj)
      .forEach((k) => {
        n[camelToSnake(k)] = keysToSnake(obj[k]);
      });

    return n;
  } else if (Array.isArray(obj)) {
    return obj.map((i) => {
      return keysToSnake(i);
    });
  }
 return obj;
}
