import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Button from '../../atoms/Button/Button';
import Header from '../../atoms/Header/Header';
import Input from '../../atoms/Input/Input';
import { ColorType, BorderRadiusType, BorderType } from '../../../types/types';
import ModalWrapper from '../../molecules/ModalWrapper/ModalWrapper';
import P from '../../atoms/Paragraph/Paragraph';
import { FetcherService } from '../../../utils/FetcherService';
import { getValueType, handleSendType } from '../../../types/types';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../routes/routes';

const inputProps = {
	border: 'none' as BorderType,
	borderBottom: 'bottom' as BorderType,
	borderRadius: 'none' as BorderRadiusType,
	color: 'landingHeaderBlue' as ColorType,
	padding: '1.6rem 0 0.8rem 0',
	placeholderColor: 'landingHeaderBlue' as ColorType,
};

const getValue: getValueType = e => {
	const target = e.target as HTMLTextAreaElement;
	const value = target.value;
	return value;
};

export interface LoginComponentInterface {
	isVisible?: boolean;
	handleToggleForm: () => void;
	setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoginComponent: React.FC<LoginComponentInterface> = ({
	handleToggleForm,
	isVisible,
	setIsVisible
}) => {
	const [email, setEmail] = useState<string>(''),
		[password, setPassword] = useState<string>(''),
		[isLoading, setLoading] = useState<boolean>(false),
		[errorMessage, setErrorMessage] = useState<string | null>(null),
		[validForm, setValidForm] = useState<boolean | undefined>(undefined),
		[emailValidationError, setEmailValidationError] = useState<string>(''),
		[passwordValidationError, setPasswordValidationError] = useState<string>(''),
		[isLoginButtonEnabled, setIsLoginButtonEnabled] = useState<boolean>(false);

		const loginRef = useRef<HTMLInputElement | null>(null);

		const history = useHistory();

	useEffect(() => {
		setTimeout(() => {
			setErrorMessage(sessionStorage.getItem('errorMessage'));
		}, 100);
		return () => {
			sessionStorage.removeItem('errorMessage');
		};
	}, []);

	useEffect(() => {
		if( isVisible ) {
			document.getElementById('input-login')?.focus()
		} else {
			setEmailValidationError('');
			setPasswordValidationError('');
		}
	},[isVisible])

	useEffect(() => {
		if(emailValidationError) {
			setEmailValidationError('');
		}
		if(passwordValidationError) {
			setPasswordValidationError('');
		}
		if(email.length > 2 && password.length > 2) {
			setIsLoginButtonEnabled(true)
		} else {
			setIsLoginButtonEnabled(false)
		}
	}, [email, password])

	const handleEnterSend = (e:React.KeyboardEvent) => {
		if(e.key === 'Enter') {
			handleSend(e)
		}
	}

	const handleSend: handleSendType = e => {
		e.preventDefault();
		setValidForm(undefined);
		setLoading(true);
		if(isLoginButtonEnabled) {
			FetcherService.login(email, password)
				.then(response => {
					const data = JSON.parse(response);
					localStorage.setItem('token', data.access);
					localStorage.setItem('refresh', data.refresh);
					// window.location.reload();
					history.push(routes.homepage)
				})
				.catch(error => {
						const { response } = error;
						if(response.data.email) {
							setEmailValidationError(response.data.email[0]);
						}
						if(response.data.password) {
							setPasswordValidationError(response.data.password[0]);
						}
						if(response.data.detail) {
							setEmail('');
							setPassword('');
							setPasswordValidationError(response.data.detail);
							setEmailValidationError(response.data.detail);
						}
					setValidForm(false);
					setLoading(false);
				});
		}
	};
	return (
		<ModalWrapper isVisible={isVisible} handleToggleForm={handleToggleForm} setIsVisible={setIsVisible}>
			<>
			<Header
				variant='h2'
				size='contactFormHeader'
				color='landingHeaderBlue'
				weight='light'
			>
				Log in
			</Header>
			<InputWrapper>
				<FullWidthContainer>
					<StyledInput
						{...inputProps}
						id="input-login"
						placeholder={emailValidationError ? emailValidationError : 'Login'}
						isError={emailValidationError ? true : false}
						name='Login'
						ref={loginRef}
						onChange={e => setEmail(getValue(e))}
						autoFocus={isVisible}
						onKeyDown={ (e: React.KeyboardEvent) => handleEnterSend(e)}
						value={email}
					/>
					<StyledInput
						{...inputProps}
						placeholder={passwordValidationError ? passwordValidationError : 'Password'}
						isError={passwordValidationError ? true : false}
						type='password'
						name='Password'
						onChange={e => setPassword(getValue(e))}
						onKeyDown={ (e: React.KeyboardEvent) => handleEnterSend(e)}
						value={password}
					/>
				</FullWidthContainer>
			</InputWrapper>
			<StyledFullWidthContainer>
				<a href='#'>
					<P color='landingHeaderBlue' size='p4'>
						Forgot your password?
					</P>
				</a>
			</StyledFullWidthContainer>
			<ButtonWrapper>
				{/* Register modal button */}
				{/* <Button
					border='regular'
					borderColor='landingHeaderBlue'
					borderRadius='regular'
					fontColor='landingHeaderBlue'
					backgroundColor='transparent'
					height='navigation'
					width='navigation'
					fontWeight='extraBold'
					onClick={(e: React.MouseEvent) => {
						handleToggleForm();
						e.preventDefault();
					}}
				>
					Register
				</Button> */}
				<SendButton
					border='regular'
					borderColor={isLoginButtonEnabled ? 'landingHeaderBlue' : 'searchGrey'}
					borderRadius='regular'
					fontColor='white'
					backgroundColor={isLoginButtonEnabled ? 'landingHeaderBlue' : 'searchGrey'}
					height='navigation'
					width='navigation'
					fontWeight='extraBold'
					onClick={(e: React.MouseEvent) => handleSend(e)}
				>
					Login
				</SendButton>
			</ButtonWrapper>
			</>
		</ModalWrapper>
	);
};

const StyledInput = styled(Input)<{isError?: boolean}>`
	outline: 0;
	margin-top: 1rem;

	&::placeholder {
		color: ${({theme, isError}) => isError ? 'red' : 'landingHeaderBlue'};
	}
`;

const InputWrapper = styled.div`
	display: grid;
	grid-template-columns: 60rem;
	grid-gap: 2rem;
	width: 100%;
	margin-top: 4rem;
	justify-content: center;
`;

const FullWidthContainer = styled.div`
	grid-column: 1/3;
`;

const StyledFullWidthContainer = styled(FullWidthContainer)`
	display: flex;
	margin-top: 3rem;
	justify-content: flex-end;
`;

const CloseButton = styled.div`
	position: absolute;
	right: 3.5rem;
	top: 3rem;
	transform: translateY(-50%);
	z-index: 5;
	height: 2rem;
	width: 2rem;
	line-height: 4rem;
	text-align: center;
	vertical-align: middle;
	color: ${({ theme }) => theme.color.landingHeaderBlue};
	:after {
		content: '\\e907';
		font-family: ${({ theme }) => theme.font.icon};
		font-size: 1.6rem;
	}
	:hover {
		cursor: pointer;
	}
`;

const ButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-top: 5.6rem;
`;

const SendButton = styled(Button)`
	margin-left: 2rem;
`;

export default LoginComponent;
