import React from 'react';
import styled from 'styled-components';
import { ListItemInterface } from '../../../types/types';

const ListItem: React.FC<ListItemInterface> = ({ children, color }) => (
	<Item color={color}>{children}</Item>
);

const Item = styled.li<ListItemInterface>`
	font-size: ${({ theme }) => `${theme.font.size.paragraph.p4}rem`};
	margin-top: 0.8rem;
	color: ${({ theme, color }) => (color ? theme.color[color] : 'inherit')};
`;

export default ListItem;
