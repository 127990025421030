import styled, { css } from 'styled-components';
import { IconInterface } from '../../../types/types';

const Icon = styled.i<IconInterface>`
	${({ blockSize }) =>
		blockSize &&
		css`
			display: block;
			position: relative;
			height: ${`${blockSize}rem`};
			width: ${`${blockSize}rem`};
		`}

	&:before {
		font-family: ${({ theme }) => theme.font.icon};
    content: '${({ theme, iconType }) => theme.icons[iconType]}';
    
    ${({ color }) =>
			color &&
			css`
				color: ${({ theme }) => theme.color[color]};
			`}

		${({ weight }) =>
			weight &&
			css`
				font-weight: ${({ theme }) => theme.font.weight[weight]};
			`}

    ${({ size }) =>
			size &&
			css`
				font-size: ${`${size}rem`};
			`}

		${({ blockSize }) =>
			blockSize &&
			css`
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			`}
	}
`;

export default Icon;
