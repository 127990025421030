import { createGlobalStyle } from 'styled-components';
import theme from './MainTheme';

const MainTheme = createGlobalStyle`

    // /* roboto-300 - latin */
    // @font-face {
    // font-family: 'Roboto';
    // font-style: normal;
    // font-weight: 300;
    // src: url('../fonts/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
    // src: local('Roboto Light'), local('Roboto-Light'),
    //     url('../fonts/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    //     url('../fonts/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    //     url('../fonts/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */
    //     url('../fonts/roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    //     url('../fonts/roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
    // }
    // /* roboto-regular - latin */
    // @font-face {
    // font-family: 'Roboto';
    // font-style: normal;
    // font-weight: 400;
    // src: url('../fonts/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
    // src: local('Roboto'), local('Roboto-Regular'),
    //     url('../fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    //     url('../fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    //     url('../fonts/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
    //     url('../fonts/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    //     url('../fonts/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
    // }
    // /* roboto-500 - latin */
    // @font-face {
    // font-family: 'Roboto';
    // font-style: normal;
    // font-weight: 500;
    // src: url('../fonts/roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
    // src: local('Roboto Medium'), local('Roboto-Medium'),
    //     url('../fonts/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    //     url('../fonts/roboto-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    //     url('../fonts/roboto-v20-latin-500.woff') format('woff'), /* Modern Browsers */
    //     url('../fonts/roboto-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    //     url('../fonts/roboto-v20-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
    // }
    // /* roboto-700 - latin */
    // @font-face {
    // font-family: 'Roboto';
    // font-style: normal;
    // font-weight: 700;
    // src: url('../fonts/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
    // src: local('Roboto Bold'), local('Roboto-Bold'),
    //     url('../fonts/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    //     url('../fonts/roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    //     url('../fonts/roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */
    //     url('../fonts/roboto-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    //     url('../fonts/roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
    // }
    // /* roboto-900 - latin */
    // @font-face {
    // font-family: 'Roboto';
    // font-style: normal;
    // font-weight: 900;
    // src: url('../fonts/roboto-v20-latin-900.eot'); /* IE9 Compat Modes */
    // src: local('Roboto Black'), local('Roboto-Black'),
    //     url('../fonts/roboto-v20-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    //     url('../fonts/roboto-v20-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    //     url('../fonts/roboto-v20-latin-900.woff') format('woff'), /* Modern Browsers */
    //     url('../fonts/roboto-v20-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
    //     url('../fonts/roboto-v20-latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
    // }

    @font-face {
        font-family: 'icomoon';
        src: url('../../../fonts/icomoon.woff2') format('woff2'),
            url('../../../fonts/icomoon.woff') format('woff'),
            url('../../../fonts/icomoon.ttf') format('ttf'),
            url('../../../fonts/icomoon.svg') format('svg');
    }


    *, *::before, *::after {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        padding: 0;
        margin: 0;
        outline: none;
    }

    a {
        color: var(--black);
        text-decoration: none;
    }

    ul, ol {
        list-style: none;
    }

    // textarea {
    //     resize: none !important;
    // }

    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
    margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }

    * {
        outline: none !important;
    }

    :focus {
        outline: 0;
    }

    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }

    body {
        line-height: 1;
    }

    ol, ul {
        list-style: none;
    }

    blockquote, q {
        quotes: none;
    }

    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    input[type=search]::-webkit-search-cancel-button,
    input[type=search]::-webkit-search-decoration,
    input[type=search]::-webkit-search-results-button,
    input[type=search]::-webkit-search-results-decoration {
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    input[type=search] {
        -webkit-appearance: none;
        -moz-appearance: none;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
    }

    textarea {
        overflow: auto;
        vertical-align: top;
        resize: vertical;
    }
    audio,
    canvas,
    video {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        max-width: 100%;
    }
    audio:not([controls]) {
        display: none;
        height: 0;
    }
    [hidden] {
        display: none;
    }
    html {
        font-size: 100%; /* 1 */
        -webkit-text-size-adjust: 100%; /* 2 */
        -ms-text-size-adjust: 100%; /* 2 */
        overflow-x: hidden;
    }
    a:focus {
        outline: thin dotted;
    }
    a:active,
    a:hover {
        outline: 0;
    }
    img {
        border: 0; /* 1 */
        -ms-interpolation-mode: bicubic; /* 2 */
    }
    figure {
        margin: 0;
    }
    form {
        margin: 0;
    }
    fieldset {
        border: 1px solid #c0c0c0;
        margin: 0 2px;
        padding: 0.35em 0.625em 0.75em;
    }
    legend {
        border: 0; /* 1 */
        padding: 0;
        white-space: normal; /* 2 */
        *margin-left: -7px; /* 3 */
    }
    button,
    input,
    select,
    textarea {
        font-size: 100%; /* 1 */
        margin: 0; /* 2 */
        vertical-align: baseline; /* 3 */
        *vertical-align: middle; /* 3 */
    }
    button,
    input {
        line-height: normal;
    }
    button,
    select {
        text-transform: none;
    }
    button,
    html input[type="button"], /* 1 */
    input[type="reset"],
    input[type="submit"] {
        -webkit-appearance: button; /* 2 */
        cursor: pointer; /* 3 */
        *overflow: visible;  /* 4 */
    }
    button[disabled],
    html input[disabled] {
        cursor: default;
    }
    input[type="checkbox"],
    input[type="radio"] {
        box-sizing: border-box; /* 1 */
        padding: 0; /* 2 */
        *height: 13px; /* 3 */
        *width: 13px; /* 3 */
    }
    input[type="search"] {
        -webkit-appearance: textfield; /* 1 */
        -moz-box-sizing: content-box;
        -webkit-box-sizing: content-box; /* 2 */
        box-sizing: content-box;
    }
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-decoration {
        -webkit-appearance: none;
    }
    button::-moz-focus-inner,
    input::-moz-focus-inner {
        border: 0;
        padding: 0;
    }
    textarea {
        overflow: auto; /* 1 */
        vertical-align: top; /* 2 */
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    html,
    button,
    input,
    select,
    textarea {
        color: #222;
    }
    ::-moz-selection {
        background: #b3d4fc;
        text-shadow: none;
    }
    ::selection {
        background: #b3d4fc;
        text-shadow: none;
    }
    img {
        vertical-align: middle;
    }
    fieldset {
        border: 0;
        margin: 0;
        padding: 0;
    }
    textarea {
        resize: vertical;
    }
    .chromeframe {
        margin: 0.2em 0;
        background: #ccc;
        color: #000;
        padding: 0.2em 0;
    }


    html {
        font-size: 62.5%;
        height: 100%;
    }

    #root {
        height: 100%;
    }

    body {
        font-size: 1.6rem;
        font-family: ${theme.font.text};
        font-weight: ${theme.font.weight.regular};
        color: ${theme.color.black};
        height: 100%;
        background: ${theme.color.white};
    }

    .scoring-item-name-p {
        position: absolute;
        font-size: 1.6rem;
        line-height: 1.7rem;
        z-index: -1;
        padding-top: 0.3rem;
    }

    .scoring-item-name-h {
        font-weight: ${theme.font.weight.extraBold};
        position: absolute;
        font-size: 1.6rem;
        line-height: 1.92rem;
        z-index: -1;
    }
`;
export default MainTheme;
